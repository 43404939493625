import { Box, Divider, Tooltip, useMediaQuery, useTheme } from "@mui/material";
import { HiOutlineSearch } from "react-icons/hi";
import { useTranslation } from "react-i18next";
import { MdOutlineAdd } from "react-icons/md";
import { useNavigate } from "react-router";
import { useSelector } from "react-redux";
import React, { useRef } from "react";

import CustomPopoverScratch from "../../PopOver/CustomPopover";
import HeaderAddOverlay from "./HeaderAddOverlay";
import initialData from "../../../Helper/data";
import DSSelectorView from "./DSSelectorView";
import BalanceView from "./BalanceView";
import CommonView from "./CommonView";
import TabView from "./TabView";
import Icon from "../../Icon";

const DatasetHeader = () => {
  const commonRef = useRef(null);
  const s1280 = useMediaQuery("(min-width:1280px)");
  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        flex: 1,
      }}
    >
      <CommonView ref={commonRef} />
      {s1280 ? <TabView /> : <span></span>}

      <ButtonView />
    </Box>
  );
};

export default React.memo(DatasetHeader);

const ButtonView = () => {
  const navigate = useNavigate();
  const theme = useTheme();
  const dataSetData = useSelector((state) => state.boardSlice?.dataSetData);

  if (!dataSetData?.uuid)
    return (
      <span
        style={{
          width: "1rem",
        }}
      ></span>
    );

  const onClickSearch = () => {
    navigate(
      `/${initialData?.path?.organization}/${dataSetData?.uuid}/list/all`,
      {
        state: {
          highlightSearch: true,
        },
      }
    );
  };

  return (
    <Box
      display="inline-flex"
      alignItems="center"
      sx={{
        "& .MuiBadge-badge": {
          minWidth: "1.5rem",
          width: "1.5rem",
          height: "1.5rem",
          fontSize: "0.75rem",
          lineHeight: "0.75rem",
        },
      }}
    >
      
      <BalanceView />
      <DSSelectorView />
      <TransactionsAddOverlay />
      <Icon
        onClick={onClickSearch}
        icon={<HiOutlineSearch />}
        fontSize={{ xs: "1.15rem", small: "1.2rem" }}
        color={theme.palette.color.slate[700]}
        style={{
          cursor: "pointer",
          marginLeft: "1rem",
          "&:hover svg": {
            color: `${theme.palette.primary.main} !important`,
          },
        }}
      />
      <Divider
        orientation="vertical"
        sx={{
          opacity: 0.7,
          borderWidth: 1,
          height: "1rem",
          mx: { xs: "0.5rem", s1280: "1.5rem" },
        }}
      />
    </Box>
  );
};

const TransactionsAddOverlay = () => {
  const { t } = useTranslation();
  const theme = useTheme();
  return (
    <CustomPopoverScratch
      button={
        <Tooltip arrow title={t("Add")}>
          <div
            style={{
              display: "inline-flex",
              alignItems: "center",
              justifyContent: "center",
              backgroundColor: theme.palette.color.slate[200],
              borderRadius: theme.borderRadius.main,
              marginLeft: "1rem",
            }}
          >
            <Icon
              icon={<MdOutlineAdd />}
              fontSize={"1.3rem"}
              color={theme.palette.color.slate[700]}
              style={{
                cursor: "pointer",
                p: 0.5,
                "&:hover svg": {
                  color: `${theme.palette.primary.main} !important`,
                },
              }}
            ></Icon>
          </div>
        </Tooltip>
      }
    >
      <HeaderAddOverlay />
    </CustomPopoverScratch>
  );
};
