import { Box, useTheme } from "@mui/material";
import React from "react";

var Spinner = require("react-spinkit");

const SpinLoader = ({ loading, children, name = "folding-cube" }) => {
  const theme = useTheme();

  const [open, setOpen] = React.useState(false);

  React.useEffect(() => {
    if (loading) {
      setOpen(true);
    } else {
      setOpen(false);
    }
  }, [loading]);

  return (
    <Box
      sx={{
        position: "absolute",
        top: open ? "46%" : "-46%",
        right: "49%",
        zIndex: 9999999,
      }}
    >
      {children || <Spinner name={name} color={theme.palette.primary.main} />}
    </Box>
  );
};
export default SpinLoader;
