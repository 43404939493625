import {
  persistReducer,
  persistStore,
  // REHYDRATE,
  // REGISTER,
  // PERSIST,
  // FLUSH,
  // PAUSE,
  // PURGE,
} from "redux-persist";
import autoMergeLevel2 from "redux-persist/es/stateReconciler/autoMergeLevel2";
// import createTransform from "redux-persist/es/createTransform";
// import storage from "redux-persist/lib/storage";
import { configureStore } from "@reduxjs/toolkit";
// import { stringify, parse } from "flatted";
import { combineReducers } from "redux";
import localforage from "localforage";

import pathHeaderSlice from "./slices/pathHeader";
import categorySlice from "./slices/category";
import settingsSlice from "./slices/settings";
import datasetSlice from "./slices/datasets";
import globalSlice from "./slices/global";
import commonSlice from "./slices/common";
import reportSlice from "./slices/report";
import boardSlice from "./slices/board";
import chartSlice from "./slices/chart";
import appSlice from "./slices/appmain";
import rulesSlice from "./slices/rules";
import staffSlice from "./slices/staff";
import authSlice from "./slices/auth";
import tourSlice from "./slices/tour";
import loanSlice from "./slices/loan";

const rootReducer = combineReducers({
  appSlice: appSlice.reducer,
  auth: authSlice.reducer,
  pathHeader: pathHeaderSlice.reducer,
  categorySlice: categorySlice.reducer,
  settingsSlice: settingsSlice.reducer,
  tourSlice: tourSlice.reducer,
  boardSlice: boardSlice.reducer,
  chartSlice: chartSlice.reducer,
  commonSlice: commonSlice.reducer,
  rulesSlice: rulesSlice.reducer,
  staffSlice: staffSlice.reducer,
  globalSlice: globalSlice.reducer,
  datasetSlice: datasetSlice.reducer,
  reportSlice: reportSlice.reducer,
  loanSlice: loanSlice.reducer,
});
// export const transformCircular = createTransform(
//   (inboundState, key) => stringify(inboundState),
//   (outboundState, key) => parse(outboundState)
// );
const persistedReducer = persistReducer(
  {
    key: "root",
    version: 12,
    storage: localforage,
    // transforms: [transformCircular],
    stateReconciler: autoMergeLevel2,
    blacklist: [
      "boardSlice",
      "categorySlice",
      "commonSlice",
      "globalSlice",
      "datasetSlice",
      "staffSlice",
      "tourSlice",
      "loanSlice",
      "appSlice",
      "reportSlice",
      "rulesSlice",
      // "settingsSlice", //never add here
    ],
  },
  rootReducer
);

const store = configureStore({
  reducer: persistedReducer,
  devTools: process.env.REACT_APP_MODE !== "production",
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
      // immutableCheck: false,
      // serializableCheck: {
      //   ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      //   ignoredPaths: ["auth.loginTime", "payload.loginTime", "tourSlice.key"],
      // },
    }),
});
export const persistor = persistStore(store);
export type RootState = ReturnType<typeof rootReducer>;

export default store;
