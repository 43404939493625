/* eslint-disable import/no-webpack-loader-syntax */
import { addMonths, endOfYear, format, startOfMonth } from "date-fns";
import Worker from "worker-loader!../../../../workers/worker.js";
import { useEffect, useMemo, useState } from "react";
import { unstable_runWithPriority } from "scheduler";
import { useQuery } from "@tanstack/react-query";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import _ from "underscore";

import {
  getStatisticsDataWithParams,
  formatAmount,
} from "../../../../Helper/data";
import useDebounce from "../../../../hooks/3-useDebounce/useDebounce";
import ComponentLoader from "../../../../components/ComponentLoader";
import { Color, Fonts } from "../../../../Helper";
import EmptyView from "./Component/EmptyView";

// eslint-disable-next-line import/no-webpack-loader-syntax
const CashDevelopmentWidget = ({ widgetType }) => {
  const dataset = useSelector((state) => state.boardSlice?.dataSetData?.uuid);
  const advanceVat = useSelector((state) => state.globalSlice.advanceVat);
  const scenario = useSelector((state) => state.globalSlice.scenario);
  const scenarioByTitle = useSelector(
    (state) => state.globalSlice.scenarioByTitle
  );

  const selectionCategoriesByID = useSelector(
    (state) => state.categorySlice?.selectionCategoriesByID
  );
  const use_global_categories = useSelector(
    (state) => state.boardSlice?.dataSetData?.use_global_categories
  );
  const _selectionCategories = useSelector(
    (state) => state.categorySlice?.selectionCategories
  );
  const selectionCategories = useMemo(() => {
    return _selectionCategories?.filter((o1) =>
      use_global_categories ? !o1?.dataset : o1?.dataset === dataset
    );
  }, [_selectionCategories, dataset, use_global_categories]);
  const period_start_date = format(startOfMonth(new Date()), "yyyy-MM-dd");
  const period_end_date = format(endOfYear(new Date()), "yyyy-MM-dd");

  //state
  const [webWorkerMonthly, setWebWorker] = useState(null);
  const [isLoading, setLoading] = useState(false);
  const [data, setData] = useState(null);

  let vat_pay_months = 0;
  if (advanceVat && advanceVat?.enabled) {
    if (
      String(advanceVat?.frequency) === "1" &&
      !advanceVat?.permanent_extension
    ) {
      vat_pay_months = 1;
    }

    if (
      String(advanceVat?.frequency) === "1" &&
      advanceVat?.permanent_extension
    ) {
      vat_pay_months = 2;
    }

    if (
      String(advanceVat?.frequency) === "2" &&
      !advanceVat?.permanent_extension
    ) {
      vat_pay_months = 3;
    }
    if (
      String(advanceVat?.frequency) === "2" &&
      advanceVat?.permanent_extension
    ) {
      vat_pay_months = 4;
    }
  }
  const WidgetData = useQuery({
    queryKey: [
      "transactions",
      {
        dataset: dataset,
        apiType: "cash_development",
        from_date: period_start_date,
        to_date: period_end_date,
        subMonthNumber: vat_pay_months,
      },
    ],
    queryFn: ({ signal }) => {
      let param = {
        config: {
          signal,
        },
        type: "transaction_monthly_chart",
        dataset: dataset,
        from_date: period_start_date,
        to_date: period_end_date,
        subMonthNumber: vat_pay_months,
      };
      if (!use_global_categories) {
        param.category_dataset = dataset;
      } else {
        param.global_category = true;
      }
      const result = getStatisticsDataWithParams(param);

      if (result) {
        return result;
      }
    },
    backgroundFetch: true,
    serialize: (data) => {
      const serializedChartData = JSON.stringify(data);
      return serializedChartData;
    },
    deserialize: (data) => {
      const deserializedChartData = JSON.parse(data);
      return deserializedChartData;
    },
    priority: 3,
    enabled:
      !!dataset &&
      !!period_end_date &&
      !!period_start_date &&
      !!webWorkerMonthly,
  });
  let isFetching =
    WidgetData.isFetching || WidgetData.isLoading || !WidgetData.isSuccess;

  //life cycle method
  useEffect(() => {
    if (!webWorkerMonthly) setWebWorker(new Worker());
    return () => {
      webWorkerMonthly?.terminate();
    };
  }, []);

  //updateComposedMonthlyChart
  useDebounce(
    () => {
      if (
        webWorkerMonthly &&
        !isFetching &&
        WidgetData?.data &&
        dataset &&
        period_end_date &&
        period_start_date
      ) {
        unstable_runWithPriority(4, () => {
          updateComposedMonthlyChart();
        });
      }
    },
    200,
    [
      isFetching,
      WidgetData?.data,
      webWorkerMonthly,
      dataset,
      period_end_date,
      period_start_date,
      selectionCategories,
    ],
    true
  );

  //function
  const updateComposedMonthlyChart = () => {
    setLoading(true);
    let obj = JSON.stringify({
      Limit: [],
      advanceVat,
      selectionCategoriesByID,
      scenario,
      start_date: period_start_date,
      end_date: period_end_date,
      booked_balances: WidgetData?.data?.booked_balances,
      Transaction_monthly_statistic: [],
      transaction_monthly_chart: WidgetData?.data?.results,
      includedScenarios: ["Base"],
      includedStates: [""],
      scenarioByTitle,
      selectionCategories,
    });

    webWorkerMonthly?.postMessage({
      type: "updateComposedMonthlyChart",
      data: obj,
    });

    webWorkerMonthly.onmessage = (e) => {
      if (e.data) {
        let { data, type } = e.data;
        if (type === "updateComposedMonthlyChart") {
          let result = JSON.parse(data);
          let groupedData = _.groupBy(result?.data, ({ name }) =>
            format(new Date(name), "yyyy-MM")
          );

          let currentMonth = format(new Date(), "yyyy-MM");
          let threeMonths = format(addMonths(new Date(), 3), "yyyy-MM");
          let endYear = format(endOfYear(new Date()), "yyyy-MM");
          setData({
            currentMonth: groupedData[currentMonth]?.[0]?.Base,
            threeMonths: groupedData[threeMonths]?.[0]?.liquidity_end_Base,
            endYear: groupedData[endYear]?.[0]?.liquidity_end_Base,
          });
          setLoading(false);
        }
      } else {
        console.log("worker client Error");
        setLoading(false);
      }
    };
  };

  return (
    <div
      style={{
        width: "100%",
        height: "90%",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      {isLoading || isFetching ? (
        <ComponentLoader
          loading
          hideNoDataPlaceholder
          height={"100%"}
          size={60}
        />
      ) : WidgetData?.data?.count > 0 ? (
        <div
          style={{
            width: "100%",
            justifyContent: "space-between",
            display: "flex",
            alignItems: "center",
          }}
        >
          <ItemView value={data?.currentMonth} title={"End of current month"} />
          <ItemView value={data?.threeMonths} title={"In 3 Month"} />
          <ItemView value={data?.endYear} title={"End of this year"} />
        </div>
      ) : (
        <EmptyView type={widgetType} />
      )}
    </div>
  );
};

export default CashDevelopmentWidget;

const ItemView = (props) => {
  const { t } = useTranslation();

  return (
    <div
      style={{
        display: "inline-flex",
        flexDirection: "column",
        alignItems: "flex-start",
        width: "32%",
        padding: "1.5rem",
        paddingBlock: "2rem",
        borderRadius: 6,
        backgroundColor:
          props?.value >= 0 ? Color.tailwind.green[50] : Color.tailwind.red[50],
      }}
    >
      <span
        style={{
          fontFamily: Fonts.Text,
          fontWeight: 500,
          fontSize: "0.7rem",
          color:
            props?.value >= 0
              ? Color.tailwind.green[500]
              : Color.tailwind.red[500],
        }}
      >
        {t(props?.title)}
      </span>
      <span
        style={{
          fontFamily: Fonts.Text,
          fontWeight: 600,
          fontSize: "1.2rem",
          marginTop: "0.5rem",
          color:
            props?.value >= 0
              ? Color.tailwind.green[700]
              : Color.tailwind.red[700],
        }}
      >
        {formatAmount({
          amount: String(parseFloat(props?.value ?? 0).toFixed(2)),
        })}
      </span>
    </div>
  );
};
