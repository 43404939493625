import React, { useContext, useEffect, useMemo, useRef, useState } from "react";
import { PiPlugs, PiPlugsConnected } from "react-icons/pi";
import { useDispatch, useSelector } from "react-redux";
import { GrConnect, GrUpdate } from "react-icons/gr";
import { Tooltip, useTheme } from "@mui/material";
import { useQuery } from "@tanstack/react-query";
import DoneIcon from "@mui/icons-material/Done";
import { useTranslation } from "react-i18next";
import Button from "@mui/lab/LoadingButton";
import { IoMdAlert } from "react-icons/io";

import {
  setIsAccountSelectOverlayOpen,
  setIsDsUuidLoading,
  setDataSource,
} from "../../../store/slices/global";
import { getFinApiDataSourceConnection, getFinApiQueryKey } from "../../../Helper/data";
import { setStageLoadingText } from "../../../store/slices/appmain";
import useDebounce from "../../../hooks/3-useDebounce/useDebounce";
import { setPopupStatus3 } from "../../../store/slices/datasets";
import { GlobalContext } from "../../../GlobalContextWrapper";
import { setAddFrom } from "../../../store/slices/settings";
import useStatusHook from "../../../hooks/useStatusHook";
import Translate from "../../../hooks/HOC/Translate";
import EndPoints from "../../../APICall/EndPoints";
import Icon from "../../../components/Icon";
import { queryClient } from "../../../App";
import APICall from "../../../APICall";

const StatusView = ({
  uuid,
  type = "button",
  onClickErrorLogs,
  refetch,
  sx,
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const theme = useTheme();

  const globalContext = useContext(GlobalContext);
  const dsRef = globalContext?.dsRef;
  const color = useRef("red");
  const title = useRef();
  const tooltip = useRef("");
  const allowClick = useRef(false);
  const cursor = useRef("auto");
  const icon = useRef(undefined);
  const notification = useRef(undefined);
  const severity = useRef("info");

  //redux
  const isFirstFetchDs = useSelector(
    (state) => state.globalSlice.isFirstFetchDs
  );
  const isDsUuidLoading = useSelector(
    (state) => state.globalSlice.isDsUuidLoading
  );

  const transactionSystemByName = useSelector(
    (state) => state.globalSlice.transactionSystemByName
  );
  const dataSourceById = useSelector(
    (state) => state.globalSlice.dataSourceById
  );
  const accountByDS = useSelector((state) => state.globalSlice.accountByDS);
  const lexOfficeId = transactionSystemByName?.["Lexoffice"]?.[0].uuid;
  const stageText = useSelector((state) => state.appSlice.stageText);

  const account = accountByDS?.[uuid]?.[0];
  const ds = dataSourceById?.[uuid]?.[0];

  //state
  const [requesting, setRequesting] = useState(false);
  const [error, setError] = useState(false);

  //api
  const getDataSource = async () => {
    await APICall("get", EndPoints.integrations).then((response) => {
      if (response.status === 200 && response.data) {
        let data = response.data.results;
        dispatch(setDataSource(data));
        setRequesting(false);
      }
    });
  };

  const getApiKey = async (integration_id) => {
    await APICall(
      "get",
      EndPoints.integrations + `${integration_id}/external/config/`
    )
      .then((response) => {
        if (response.status === 200 && response.data) {
          if (response.data?.authorization_url) {
            dispatch(
              setAddFrom({
                integration_id: integration_id,
                targetUrl: window.location.pathname,
              })
            );
            dispatch(setIsDsUuidLoading(integration_id));
            dispatch(setPopupStatus3(null));
            setTimeout(() => {
              let win = window.open(response.data?.authorization_url, "_self");
              win.focus();
            }, 100);
          }
        }
      })
      .finally(() => {
        setRequesting(false);
      });
  };

  const updateDataSourceByID = async (ds, obj, dsType) => {
    await APICall("patch", EndPoints.integrations + `${ds?.uuid}/`, obj).then(
      (response) => {
        if (response.status === 200 && response.data) {
          if (dsType !== "finApi") {
            getApiKey(ds?.uuid);
          }
        }
      }
    );
  };

  const reconnectFinapiDS = async (ds) => {
    await APICall(
      "post",
      EndPoints.integrations + `${ds?.uuid}/finapi/reconnect/`,
      {}
    ).then((response) => {
      if (response.status === 200 && response.data) {
        queryClient.removeQueries({
          queryKey: FinApiQueryKey,
        });
        getDataSource();
      }
    });
  };

  const getDataSourceError = async (id) => {
    let data = null;

    await APICall("get", EndPoints.integrations + `${id}/logs/`).then(
      (response) => {
        if (response.status === 200 && response.data) {
          data = response.data;
        }
      }
    );
    return data;
  };

  const FinApiQueryKey = getFinApiQueryKey({ data_source: ds?.uuid });

  const IntegrationsQueryKey = useMemo(
    () => [
      "integrations",
      {
        data_source: ds?.uuid,
        apiType: "data_source_external_connections",
      },
    ],
    [ds?.uuid]
  );

  const data_source_by_uuid_finapi_connections = useStatusHook(null, {
    queryKey: FinApiQueryKey,
  });
  const data_source_by_uuid_external_connections = useStatusHook(null, {
    queryKey: IntegrationsQueryKey,
  });

  const data_source_error_by_uuid = useQuery({
    queryKey: [
      "integrations",
      {
        data_source: ds?.uuid,
        apiType: "data_source_fetch_errors",
      },
    ],
    queryFn: ({ signal }) => {
      const result = getDataSourceError(ds?.uuid);
      if (result) {
        return result;
      }
    },
    backgroundFetch: true,
    priority: 5,
    staleTime: 1000 * 60 * 60 * 24, //24hour
    enabled:
      !!ds?.uuid &&
      !!ds?.is_connected &&
      !!ds?.last_sync_date &&
      global.isFirstFetchUuid?.length === 0,
  });

  const isLoading =
    data_source_by_uuid_external_connections?.isFetching ||
    data_source_by_uuid_finapi_connections?.isFetching ||
    isDsUuidLoading === uuid;

  //functions
  const onLexOfficeConfirm = async ({ payload }) => {
    setRequesting(true);
    // dispatch(setIsAllHeaderApiFetched(false));
    if (ds?.state === 3 || ds?.state === 2) {
      await updateDataSourceByID(ds, { state: 1 }, "integration");
    } else {
      getApiKey(payload?.ds?.uuid);
    }
  };

  const onLexOfficeClose = () => {
    setRequesting(false);
  };

  const onClickIconView = async () => {
    if (!requesting && !isLoading) {
      if (error && onClickErrorLogs) {
        onClickErrorLogs(ds, error);
        return null;
      }
      if (ds?.type === 10) {
        return null;
      }
      if (ds?.type === 12) {
        setRequesting(true);

        if (account) {
          const connections = await queryClient.fetchQuery({
            queryKey: FinApiQueryKey,
            queryFn: async ({ signal }) => {
              try {
                const result = await getFinApiDataSourceConnection(ds?.uuid);
                return result ?? "";
              } catch (error) {
                return "";
              }
            },
            backgroundFetch: true,
          });
          if (
            connections === "NOT_YET_OPENED" ||
            connections === "ABORTED" ||
            connections === "EXPIRED" ||
            connections === "ERROR"
          ) {
            setRequesting(false);
            dispatch(setStageLoadingText(null));
            dispatch(
              setIsAccountSelectOverlayOpen({
                open: true,
                isBankSelection: true,
                payload: {
                  integration_id: ds?.uuid,
                },
              })
            );
          } else if (connections === "DISCONNECTED") {
            if (ds?.state !== 1) {
              await updateDataSourceByID(ds, { state: 1 }, "finApi");
            }
            await reconnectFinapiDS(ds);
          } else if (connections === "USER_ACTION_REQUIRED") {
            dsRef.current?.updateConsents({
              DS: ds,
              _fetchType: null,
            });
          } else if (!ds?.last_sync_date) {
            dsRef.current?.fetchAllTransaction(ds);
            setRequesting(false);
          } else {
            setRequesting(false);
          }
        } else {
          dispatch(
            setIsAccountSelectOverlayOpen({
              open: true,
              isBankSelection: true,
              payload: {
                integration_id: ds?.uuid,
              },
            })
          );
          setRequesting(false);
        }
      }
      if (ds?.type === 19) {
        if (lexOfficeId === ds?.transaction_system) {
          dispatch(
            setPopupStatus3({
              id: "simple-popper",
              overlay_type: "warn_overlay",
              open: true,
              onConfirm: onLexOfficeConfirm,
              onClose: onLexOfficeClose,
              payload: {
                title: <Translate i18nkey={"info_lexoffice_title"} />,
                message: <Translate i18nkey={"info_lexoffice_message"} />,
                confirmText: (
                  <Translate i18nkey={"info_lexoffice_confirm_text"} />
                ),
                ds,
              },
            })
          );
        } else {
          setRequesting(true);
          getApiKey(ds?.uuid);
        }
      }
    }
  };

  const checkConnections = () => {
    const options = {
      queryKey: ds?.type === 12 ? FinApiQueryKey : IntegrationsQueryKey,
    };
    queryClient.resetQueries(options);
  };

  if (ds && ds?.type === 1 && ds.internal_dataset) {
    title.current = "connection_state_connected";
    tooltip.current = t("connection_state_connected_tooltip");
    color.current = "green";
    cursor.current = "auto";
    severity.current = "success";
    icon.current = (
      <DoneIcon
        sx={{
          color: `${theme.palette?.color?.[color.current]?.[500]} !important`,
          fontSize: "1.1rem",
        }}
      />
    );
  }

  if (ds && ds?.type === 1 && !ds.internal_dataset) {
    if (account) {
      if (ds?.state === 1) {
        title.current = "connection_state_connected";
        tooltip.current = t("connection_state_connected_tooltip");
        color.current = "green";
        cursor.current = "auto";
        severity.current = "success";
        icon.current = (
          <DoneIcon
            sx={{
              color: `${theme.palette?.color?.[color.current]?.[500]} !important`,
              fontSize: "1.1rem",
            }}
          />
        );
      }
    } else {
      title.current = "connection_state_connect";
      color.current = "blue";
      severity.current = "info";
      cursor.current = "pointer";
      allowClick.current = true;
      icon.current = (
        <Icon
          icon={<GrConnect />}
          fontSize={"0.9rem"}
          color={`${theme.palette?.color?.[color.current]?.[500]} !important`}
          stroke={`${theme.palette?.color?.[color.current]?.[500]} !important`}
          style={{
            paddingLeft: "0.25rem",
          }}
        ></Icon>
      );
    }
  }

  if (ds && ds?.type === 12) {
    notification.current = undefined;
    if (account && data_source_by_uuid_finapi_connections?.data) {
      if (
        data_source_by_uuid_finapi_connections?.data === "COMPLETED" ||
        data_source_by_uuid_finapi_connections?.data === "UPDATED"
      ) {
        tooltip.current = t("connection_state_connected_tooltip");
        title.current = "connection_state_connected";
        color.current = "green";
        severity.current = "success";
        cursor.current = "auto";
        icon.current = (
          <DoneIcon
            sx={{
              color: `${theme.palette?.color?.[color.current]?.[500]} !important`,
              fontSize: "1rem",
            }}
          />
        );
        if (data_source_by_uuid_finapi_connections?.data === "UPDATED") {
          notification.current = true;
          tooltip.current = t(
            "connection_state_connected_with_action_required_tooltip"
          );
        }
      } else if (
        !ds?.is_connected &&
        data_source_by_uuid_finapi_connections?.data === "DISCONNECTED"
      ) {
        title.current = "connection_state_reconnect";
        color.current = "red";
        cursor.current = "pointer";
        severity.current = "error";
        allowClick.current = true;
        tooltip.current = t("connection_state_reconnect");
        icon.current = (
          <Icon
            icon={<GrConnect />}
            fontSize={"0.9rem"}
            color={`${theme.palette?.color?.[color.current]?.[500]} !important`}
            stroke={`${theme.palette?.color?.[color.current]?.[500]} !important`}
            style={{
              paddingLeft: "0.25rem",
            }}
          ></Icon>
        );
      } else if (
        !data_source_by_uuid_finapi_connections?.data ||
        data_source_by_uuid_finapi_connections?.data === "UNAUTHORIZED_ACCESS"
      ) {
        allowClick.current = false;
        title.current = "connection_state_unauthorized_access";
        tooltip.current = t("connection_state_unauthorized_access_tooltip");
        color.current = "red";
        severity.current = "error";
        icon.current = null;
        cursor.current = "auto";
      } else {
        tooltip.current = t("connection_state_update_tooltip");
        title.current = "connection_state_update";
        color.current = "blue";
        cursor.current = "pointer";
        severity.current = "info";
        icon.current = (
          <Icon
            icon={<GrUpdate />}
            fontSize={"0.8rem"}
            stroke={`${theme.palette?.color?.[color.current]?.[500]} !important`}
            color={`${theme.palette?.color?.[color.current]?.[500]} !important`}
            style={{
              paddingLeft: "0.25rem",
            }}
            path={{
              strokeWidth: 3,
            }}
          ></Icon>
        );
        allowClick.current = true;
      }
    } else if (
      account &&
      !ds.last_sync_date &&
      isFirstFetchDs !== 0 &&
      global.isFirstFetchUuid?.includes(ds.uuid)
    ) {
      icon.current = (
        <Icon
          icon={<GrConnect />}
          fontSize={"0.9rem"}
          color={`${theme.palette?.color?.[color.current]?.[500]} !important`}
          stroke={`${theme.palette?.color?.[color.current]?.[500]} !important`}
          style={{
            paddingLeft: "0.25rem",
          }}
        ></Icon>
      );
      tooltip.current = t("Fetching Data");
      color.current = "green";
      severity.current = "success";
      title.current = "Fetching Data";
    } else if (!account) {
      title.current = "connection_state_connect";
      color.current = "red";
      cursor.current = "pointer";
      severity.current = "error";
      allowClick.current = true;
      tooltip.current = t("connection_state_connect");
      icon.current = (
        <Icon
          icon={<PiPlugsConnected />}
          fontSize={"1.1rem"}
          color={`${theme.palette?.color?.[color.current]?.[500]} !important`}
          stroke={`${theme.palette?.color?.[color.current]?.[500]} !important`}
          strokeWidth={8}
          style={{
            paddingLeft: "0.25rem",
          }}
        ></Icon>
      );
    }
  }

  if (ds && ds?.type === 19) {
    if (data_source_by_uuid_external_connections?.data === "UPDATED") {
      title.current = "connection_state_connected";
      tooltip.current = t("connection_state_connected_tooltip");
      color.current = "green";
      severity.current = "success";
      allowClick.current = false;
      cursor.current = "auto";
      icon.current = (
        <DoneIcon
          sx={{
            fontSize: "1.1rem",
            color: color.current
              ? `${theme.palette?.color?.[color.current]?.[500]} !important`
              : "inherit",
          }}
        />
      );
    } else if (
      !data_source_by_uuid_external_connections?.data ||
      data_source_by_uuid_external_connections?.data === "UNAUTHORIZED_ACCESS"
    ) {
      allowClick.current = false;
      title.current = "connection_state_unauthorized_access";
      tooltip.current = t("connection_state_unauthorized_access_tooltip");
      cursor.current = "auto";
      color.current = "red";
      severity.current = "error";
      icon.current = null;
    } else {
      if (lexOfficeId === ds?.transaction_system) {
        if (ds.state === 1) {
          tooltip.current = t("integrations_connection_state_update_tooltip");
          title.current = "connection_state_update";
          color.current = "blue";
          cursor.current = "pointer";
          severity.current = "info";
          icon.current = (
            <Icon
              icon={<GrUpdate />}
              fontSize={"0.8rem"}
              stroke={`${theme.palette?.color?.[color.current]?.[500]} !important`}
              color={`${theme.palette?.color?.[color.current]?.[500]} !important`}
              style={{
                paddingLeft: "0.25rem",
              }}
              path={{
                strokeWidth: 3,
              }}
            ></Icon>
          );
          allowClick.current = true;
        }
      } else {
        title.current = "connection_state_disconnected";
        tooltip.current = t("connection_state_disconnected_tooltip");
        color.current = "red";
        severity.current = "error";
        icon.current = (
          <Icon
            icon={<PiPlugs />}
            fontSize={"1.1rem"}
            color={`${theme.palette?.color?.[color.current]?.[500]} !important`}
            stroke={`${theme.palette?.color?.[color.current]?.[500]} !important`}
            style={{
              paddingLeft: "0.25rem",
            }}
          ></Icon>
        );

        if (ds?.state === 3) {
          title.current = "connection_state_disconnected";
          tooltip.current = t("connection_state_disconnected_tooltip");
          color.current = "red";
          severity.current = "error";
          allowClick.current = false;
          icon.current = (
            <Icon
              icon={<PiPlugs />}
              fontSize={"1.1rem"}
              color={`${theme.palette?.color?.[color.current]?.[500]} !important`}
              stroke={`${theme.palette?.color?.[color.current]?.[500]} !important`}
              style={{
                paddingLeft: "0.25rem",
              }}
            ></Icon>
          );
        }
      }
    }
  }

  if (ds.state === 3) {
    title.current = "connection_state_reconnect";
    color.current = "red";
    cursor.current = "pointer";
    severity.current = "error";
    allowClick.current = true;
    tooltip.current = t("connection_state_reconnect");
    icon.current = (
      <Icon
        icon={<GrConnect />}
        fontSize={"0.9rem"}
        color={`${theme.palette?.color?.[color.current]?.[500]} !important`}
        stroke={`${theme.palette?.color?.[color.current]?.[500]} !important`}
        style={{
          paddingLeft: "0.25rem",
        }}
      ></Icon>
    );
  }

  if (ds?.state === 2 || ds?.type === 10) {
    title.current = "connection_state_disconnected";
    tooltip.current = t("connection_state_disconnected_tooltip");
    color.current = "red";
    severity.current = "error";
    allowClick.current = false;
    cursor.current = "not-allowed";
    icon.current = (
      <Icon
        icon={<PiPlugs />}
        fontSize={"1.1rem"}
        color={`${theme.palette?.color?.[color.current]?.[500]} !important`}
        stroke={`${theme.palette?.color?.[color.current]?.[500]} !important`}
        style={{
          paddingLeft: "0.25rem",
        }}
      ></Icon>
    );
  }

  if (error) {
    title.current = "Errors";
    tooltip.current = t("Error");
    allowClick.current = true;
    color.current = "red";
    severity.current = "error";
    cursor.current = "pointer";
    icon.current = (
      <Icon
        icon={<IoMdAlert />}
        fontSize={"1.1rem"}
        color={`${theme.palette?.color?.[color.current]?.[500]} !important`}
        stroke={`${theme.palette?.color?.[color.current]?.[500]} !important`}
        style={{
          paddingLeft: "0.25rem",
        }}
      ></Icon>
    );
  }
  if (isFirstFetchDs !== 0 && global.isFirstFetchUuid?.includes(ds?.uuid)) {
    tooltip.current = t("Fetching Data");
    color.current = "green";
    severity.current = "success";
    title.current = "Fetching Data";
    icon.current = (
      <Icon
        icon={<GrConnect />}
        fontSize={"0.9rem"}
        color={
          color.current
            ? `${theme.palette?.color?.[color.current]?.[500]} !important`
            : "inherit"
        }
        stroke={
          color.current
            ? `${theme.palette?.color?.[color.current]?.[500]} !important`
            : "inherit"
        }
        style={{
          paddingLeft: "0.25rem",
        }}
      ></Icon>
    );
  }

  if (requesting) {
    title.current = "Request sent";
  }

  useEffect(() => {
    if (requesting && ds && ds?.type === 12) {
      if (
        data_source_by_uuid_finapi_connections?.data === "COMPLETED" ||
        data_source_by_uuid_finapi_connections?.data === "UPDATED" ||
        !stageText
      ) {
        setRequesting(false);
      }
    }
  }, [data_source_by_uuid_finapi_connections?.data, ds, requesting, stageText]);

  useEffect(() => {
    if (requesting && ds && ds?.type === 19) {
      if (data_source_by_uuid_external_connections?.data === "UPDATED") {
        setRequesting(false);
      }
    }
  }, [data_source_by_uuid_external_connections?.data, ds, requesting]);

  useDebounce(
    () => {
      if (refetch && ds?.uuid && !global.isFirstFetchUuid?.includes(ds?.uuid)) {
        checkConnections();
      }
    },
    200,
    [refetch],
    true
  );

  useEffect(() => {
    if (data_source_error_by_uuid?.data?.count > 0) {
      const [firstItem] = data_source_error_by_uuid.data?.results;
      if (
        firstItem?.total_error_entries > 0 &&
        ds?.state !== 2 &&
        !global.isFirstFetchUuid?.includes(ds?.uuid)
      ) {
        setError(firstItem);
      } else {
        setError(false);
      }
    }
  }, [data_source_error_by_uuid?.data]);

  // if (ds.type === 19 && ds.note === "GetMyInvoices") {
  //   console.log(
  //     "🚀 ds:",
  //     ds,
  //     data_source_by_uuid_external_connections,
  //     isFirstFetchDs,
  //     global?.isFirstFetchUuid
  //   );
  // }

  // if (ds.type === 12) {
  //   console.log(
  //     "🚀finapi",
  //     ds?.title,
  //     data_source_by_uuid_finapi_connections?.data,
  //     { ds, account }
  //     // isFirstFetchDs !== 0 && global?.isFirstFetchUuid?.includes(ds?.uuid),
  //     // global?.isFirstFetchUuid
  //   );
  // }

  return (
    <Tooltip arrow placement="top" title={tooltip.current} followCursor>
      <span>
        {type === "dot" ? null : (
          <Button
            variant="outlined"
            size="small"
            onClick={allowClick.current ? onClickIconView : undefined}
            loading={
              (isFirstFetchDs !== 0 &&
                global?.isFirstFetchUuid?.includes(ds?.uuid)) ||
              isLoading ||
              requesting
            }
            // loading
            loadingPosition={
              (isFirstFetchDs !== 0 &&
                global?.isFirstFetchUuid?.includes(ds?.uuid)) ||
              requesting
                ? "start"
                : "center"
            }
            startIcon={(!isLoading || requesting) && icon.current}
            disableElevation
            disableRipple={!allowClick.current}
            color="primary"
            sx={{
              height: "1.8rem",
              fontSize: "0.7rem",
              lineHeight: "inherit",
              minWidth: type === "alert" ? "8rem" : "none",
              width: "fit-content",
              borderRadius: theme.borderRadius.main,
              color: `${theme.palette?.color?.[color.current]?.[500]} !important`,
              backgroundColor: theme.palette?.color?.[color.current]?.[50],
              cursor: cursor.current,
              fontWeight: 600,
              border: "none",
              borderColor: `${theme.palette?.color?.[color.current]?.[500]} !important`,
              textTransform: "initial",
              "& .MuiCircularProgress-root": {
                color: `${theme.palette?.color?.[color.current]?.[500]} !important`,
              },

              "&: hover": {
                backgroundColor: `${theme.palette?.color?.[color.current]?.[allowClick.current ? 100 : 50]} !important`,
                border: "none",
              },
              ...sx,
            }}
          >
            {(!isLoading || requesting) && t(title.current)}
            {isLoading || requesting || !notification.current ? null : " (!) "}
          </Button>
        )}
        {type === "dot" ? (
          <div
            style={{
              position: "absolute",
              left: "2%",
              top: "42%",
              width: "8px",
              height: "8px",
              borderRadius: "4px",
              backgroundColor: theme.palette?.color?.[color.current]?.[500],
              marginRight: "0.5rem",
              ...sx,
            }}
          ></div>
        ) : null}
      </span>
    </Tooltip>
  );
};
export default StatusView;
