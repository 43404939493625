import {
  ListItemButton,
  ListItemText,
  ListItemIcon,
  Typography,
  useTheme,
  Divider,
  Button,
  Stack,
  Alert,
  Badge,
  List,
  Grid,
  Box,
} from "@mui/material";
import TipsAndUpdatesTwoToneIcon from "@mui/icons-material/TipsAndUpdatesTwoTone";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import React, { useContext, useEffect, useRef, useState } from "react";
import { Outlet, useLocation, useNavigate } from "react-router";
import { HiMagnifyingGlassCircle } from "react-icons/hi2";
import { useDispatch, useSelector } from "react-redux";
import DeleteIcon from "@mui/icons-material/Delete";
import UpdateIcon from "@mui/icons-material/Update";
import { RiFileExcel2Fill } from "react-icons/ri";
import { PiMagicWandFill } from "react-icons/pi";
import { useTranslation } from "react-i18next";
import { enqueueSnackbar } from "notistack";

import useUpdateEffect from "../../../hooks/4-useUpdateEffect/useUpdateEffect";
import { setDataSetData, setDataSetList } from "../../../store/slices/board";
import CustomPopoverScratch from "../../../components/PopOver/CustomPopover";
import CategoryLevelAttentionOverlay from "./CategoryLevelAttentionOverlay";
import ThemeToggle from "../../../components/ToggleGroupeView/ThemeToggle";
import FinbanInfoBulb from "../../../components/FInbanInfo/FinbanInfoBulb";
import TailwindButton from "../../../components/Overlay/TailwindButton";
import { setStageLoadingText } from "../../../store/slices/appmain";
import ComponentLoader from "../../../components/ComponentLoader";
import { setPopupStatus3 } from "../../../store/slices/datasets";
import { setCategories } from "../../../store/slices/category";
import { GlobalContext } from "../../../GlobalContextWrapper";
import ActionButton from "../../../components/ActionButton";
import { setProfile } from "../../../store/slices/settings";
import CustomPopover from "../../../components/PopOver";
import AddButton from "../../../components/AddButton";
import ThemeTabs from "../../../components/ThemeTabs";
import EndPoints from "../../../APICall/EndPoints";
import { Constant } from "../../../Helper";
import { queryClient } from "../../../App";
import TreeItem from "./Tree/tree-item";
import APICall from "../../../APICall";
import store from "../../../store";
import { tree } from "./Tree/data";
import TreeView from "./TreeView";

const tabOptions = [
  {
    value: 1,
    label: "Tab_Inflow",
  },
  {
    value: 2,
    label: "Tab_Outflow",
  },
];
const CategoryOptionViewCustomImportOptions = [
  {
    uuid: 1,
    title: "category_option_custom_import_1",
    icon: (
      <RiFileExcel2Fill
        style={{ fontSize: "1.3rem", marginInline: "0.25rem" }}
      />
    ),
    description: "Coming Soon",
    disabled: true,
    isAction: true,
  },
  {
    uuid: 2,
    title: "category_option_custom_import_2",
    icon: (
      <RiFileExcel2Fill
        style={{ fontSize: "1.3rem", marginInline: "0.25rem" }}
      />
    ),
    description: "Coming Soon",
    disabled: true,
    isAction: true,
  },
];

const Categories = () => {
  const { t } = useTranslation();
  const treeRef = useRef(null);
  const categoryRef = useRef(null);
  const theme = useTheme();

  //redux
  const dataset = useSelector((state) => state.boardSlice?.dataSetData?.uuid);
  const email = useSelector((state) => state.settingsSlice?.profile?.email);
  const isFinbanAccount = email?.includes("@finban.io");

  //state
  const [value, setValue] = useState(1);
  const [isSaving, setIsSaving] = useState(false);

  //function
  const onClickAddNewCategory = (e) => {
    e?.stopPropagation();
    const categoryData = treeRef.current?.categoryData;
    if (categoryData) {
      categoryRef?.current?.onClickAddNewCategory(e, {
        value,
        setValue,
        categoryData,
      });
    }
  };

  if (!dataset) {
    return null;
  }
  return (
    <Box
      sx={{
        width: "100%",
        height: "100%",
        backgroundColor: theme.palette.color.appThemeBg,
        pt: "1.5rem",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        position: "relative",
      }}
    >
      <Box
        sx={{
          display: "inline-flex",
          flexDirection: "column",
          width: { xs: "95%", s1280: "80%" },
          alignItems: "center",
          overflow: "auto",
          paddingBottom: "3rem",
        }}
      >
        <Box
          sx={{
            width: "100%",
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            alignItems: "center",
            mb: "1rem",
          }}
        >
          <div style={{ display: "flex", alignItems: "center" }}>
            <TabsView value={value} setValue={setValue} />
            {isSaving ? (
              <Alert
                icon={<UpdateIcon />}
                severity="success"
                sx={{
                  boxShadow: "none !important",
                  fontSize: "0.8rem",
                  padding: "0.35rem 0.75rem",
                  display: "inline-flex",
                  alignItems: "center",
                  backgroundColor: theme.palette.color.slate[100],
                  alignSelf: "center",
                  "& .MuiAlert-icon": {
                    color: theme.palette.color.slate[700],
                    fontSize: "1rem",
                    py: "0rem",
                    alignItems: "center",
                  },
                  "& .MuiAlert-message": {
                    color: theme.palette.color.slate[700],
                    textAlign: "left",
                    py: "0rem",
                    display: "inline-flex",
                  },
                }}
              >
                {t("Saving")}
              </Alert>
            ) : null}
          </div>

          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "flex-end",
            }}
          >
            {Constant.isStag || Constant.isTest ? <CategoryTypeSwitch /> : null}

            {isFinbanAccount ? (
              <CustomPopoverScratch
                button={<OptionButtonView />}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "left",
                }}
                transformOrigin={{
                  vertical: "top",
                  horizontal: "left",
                }}
                paperStyle={{
                  borderRadius: theme.borderRadius.main,
                  boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.15)",
                }}
                mr={5}
              >
                <OptionsView categoryRef={categoryRef} />
              </CustomPopoverScratch>
            ) : null}

            <Stack
              direction="row"
              alignItems={"center"}
              sx={{ position: "relative" }}
            >
              <AddButton
                tooltipLabel={t("Add_New_Category")}
                label={t("Add_New_Category")}
                onClick={onClickAddNewCategory}
                isNKey
                sx={{
                  mr: "1rem",
                }}
              />
              <FinbanInfoBulb url="https://finban.io/documentation/categories/" />
            </Stack>
          </div>
        </Box>
        <Grid container sx={{ mt: "0.5rem", width: "100%", pr: "40px" }}>
          <Grid item xs={1} sx={{ textAlign: "left" }}></Grid>
          <Grid item xs={7.9} sx={{ textAlign: "left", pl: "1.5rem" }}>
            <Typography
              variant={"caption"}
              fontWeight={"fontWeightBold"}
              sx={{ width: "fit-content" }}
            >
              {t("Title")}
            </Typography>
          </Grid>
          <Grid item xs={1} sx={{ textAlign: "left" }}>
            <Typography
              variant={"caption"}
              fontWeight={"fontWeightBold"}
              sx={{ width: "fit-content" }}
            >
              {t("vat")}
            </Typography>
          </Grid>
          <Grid item xs={2} sx={{ textAlign: "left" }}>
            <Typography
              variant={"caption"}
              fontWeight={"fontWeightBold"}
              sx={{ width: "fit-content" }}
            >
              {t("category_type")}
            </Typography>
          </Grid>
        </Grid>

        <TreeView
          ref={treeRef}
          categoryRef={categoryRef}
          value={value}
          isSaving={isSaving}
          setIsSaving={setIsSaving}
        />
      </Box>
      <Outlet />
    </Box>
  );
};

export default Categories;

const OptionButtonView = ({ popup_state }) => {
  const { t } = useTranslation();
  const theme = useTheme();
  return (
    <Badge
      badgeContent={t("New")}
      color="success"
      sx={{
        "& .MuiBadge-badge": {
          top: "3px",
          right: "22px",
          borderRadius: "4px",
          fontSize: "0.6rem",
          lineHeight: "0.6rem",
          height: "fit-content",
          p: "3px",
          backgroundColor: theme.palette.color.green[400],
        },
      }}
    >
      <ActionButton
        endIcon={
          <KeyboardArrowDownIcon
            sx={{
              mr: "0rem !important",
              transform: `rotate(${popup_state?.isOpen ? 180 : 0}deg)`,
              transition: "all 0.3s ease",
            }}
          />
        }
        label={t("category_options")}
        buttontype="manage2"
        sx={{ mr: "1rem" }}
      />
    </Badge>
  );
};

const OptionsViewItemButton = ({ title, onClick, theme, startIcon, t }) => {
  return (
    <Button
      onClick={onClick}
      sx={{
        border: `1px solid ${theme.palette.primary[300]}`,
        borderRadius: theme.borderRadius.main,
        color: theme.palette.primary.main,
        backgroundColor: theme.palette.primary[50],
        fontSize: "0.7rem",
        lineHeight: "normal",
        minWidth: "unset",
        py: "0.1rem",
        pl: "0.25rem",
        pr: "0.35rem",
        "&:hover": {
          backgroundColor: theme.palette.primary[100],
        },
      }}
    >
      {startIcon}
      {t(title)}
    </Button>
  );
};

const OptionViewItem = ({
  item,
  open,
  theme,
  t,
  onClick,
  onClickGuide,
  sx,
  primarySx,
  secondarySx,
}) => {
  return (
    <ListItemButton
      onClick={item?.isAction ? (e) => onClick(e, item) : undefined}
      disabled={!!item?.disabled}
      sx={{
        p: "0.5rem",
        height: "fit-content",
        borderRadius: theme.borderRadius.main,
        "&:hover": {
          backgroundColor: theme.palette.color.slate[100],
          "& #category_preset_actions": {
            display: "flex",
          },
        },
        ...sx,
      }}
    >
      {item?.icon ? (
        <ListItemIcon
          sx={{
            minWidth: "2rem",
            color: theme.palette.color.slate[700],
          }}
        >
          {item?.icon}
        </ListItemIcon>
      ) : null}
      <ListItemText
        primary={t(item?.title)}
        secondary={t(item?.description)}
        sx={{
          my: 0,
          "& .MuiListItemText-primary": {
            fontSize: "0.85rem",
            fontWeight: 500,
            color: theme.palette.color.slate[700],
            ...primarySx,
          },
          "& .MuiListItemText-secondary": {
            fontSize: "0.7rem",
            fontWeight: 400,
            color: theme.palette.color.description,
            lineHeight: "0.9rem",
            maxHeight: "1.8rem",
            WebkitLineClamp: "2",
            WebkitBoxOrient: "vertical",
            display: "-webkit-box",
            textOverflow: "ellipsis",
            overflow: "hidden",
            ...secondarySx,
          },
        }}
      />
      {item?.isAction ? null : (
        <Stack
          id="category_preset_actions"
          direction={"row"}
          gap={"0.5rem"}
          alignItems={"start"}
          sx={{
            position: "absolute",
            top: item?.description ? "0.45rem" : undefined,
            right: "0.45rem",
            display: open ? "flex" : "none",
          }}
        >
          <OptionsViewItemButton
            onClick={(e) => onClick(e, item)}
            title={"category_preset_action_preview"}
            theme={theme}
            t={t}
            startIcon={
              <HiMagnifyingGlassCircle
                style={{
                  fontSize: "1.1rem",
                  marginRight: "0.25rem",
                  color: theme.palette.primary.main,
                }}
              />
            }
          />
          <OptionsViewItemButton
            onClick={(e) => onClickGuide(e, item)}
            title={"category_preset_action_guide"}
            theme={theme}
            t={t}
            startIcon={
              <TipsAndUpdatesTwoToneIcon
                style={{
                  fontSize: "1.1rem",
                  marginRight: "0.25rem",
                  color: theme.palette.primary.main,
                }}
              />
            }
          />
        </Stack>
      )}
    </ListItemButton>
  );
};

const OptionsViewSections = ({
  options,
  categoryPresets,
  onClickOption,
  onClickGuide,
  t,
  theme,
}) => {
  if (options?.length === 0) return null;
  return (
    <>
      {options?.map((item) => (
        <OptionViewItem
          key={item?.uuid}
          item={item}
          open={item?.uuid === categoryPresets?.uuid}
          theme={theme}
          t={t}
          onClick={onClickOption}
          onClickGuide={onClickGuide}
        />
      ))}
      <Divider flexItem sx={{ my: "0.5rem" }} />
    </>
  );
};

const OptionsView = ({ categoryRef }) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const dispatch = useDispatch();
  const globalContext = useContext(GlobalContext);
  const navigate = useNavigate();

  const finbanInfoRef = globalContext?.finbanInfoRef;
  const client_last_modified_date = useRef(null);

  //redux
  const selectionCategories = useSelector(
    (state) => state.categorySlice?.selectionCategories
  );
  const profile = useSelector((state) => state.settingsSlice?.profile);

  //state
  const [options, setOptions] = useState({
    business_models_: [],
    accounting_: [],
    others: [],
  });
  const [isFetching, setIsFetching] = useState(false);
  const [anchorEl, setAnchorEl] = useState(false);
  const [categoryPresets, setCategoryPreset] = useState(false);

  //api
  const getCategoryPresets = async () => {
    setIsFetching(true);
    setAnchorEl(false);
    setCategoryPreset(null);
    await APICall("get", EndPoints.category_presets).then((response) => {
      if (response.status === 200 && response.data) {
        let business_models_ = [];
        let accounting_ = [];
        let others = [];
        response.data.results?.forEach((item) => {
          if (item?.title.includes("business_models_")) {
            business_models_.push(item);
          } else if (item?.title.includes("accounting_")) {
            accounting_.push(item);
          } else {
            others.push(item);
          }
        });
        setOptions({
          business_models_,
          accounting_,
          others,
        });
        setIsFetching(false);
      } else {
        setOptions({ business_models_: [], accounting_: [], others: [] });
        setIsFetching(false);
      }
    });
  };

  const applyCategoryPresets = async (category_preset_uuid) => {
    client_last_modified_date.current = profile?.client_last_modified_date;
    dispatch(setStageLoadingText("common_process_loader_text"));
    dispatch(setPopupStatus3(null));
    await APICall(
      "post",
      EndPoints.category_presets + `${category_preset_uuid}/apply/`,
      {
        uncategorize_existing_transactions: true,
        preset_category_update: 0,
      },
      {
        doNotCatchRespond: true,
      }
    )
      .then((response) => {
        if (response.status === 200 && response.data) {
          setTimeout(() => {
            getProfile();
          }, 4000);
        }
      })
      .catch((error) => {
        dispatch(setStageLoadingText(null));
      });
  };

  const getProfile = async (res) => {
    await APICall("get", EndPoints.profile).then(async (response) => {
      if (response.status === 200 && response.data) {
        const data = response.data;
        if (
          data?.client_last_modified_date !== client_last_modified_date.current
        ) {
          client_last_modified_date.current = null;
          await getCategories({ updatePosition: true });
          dispatch(setProfile(data));
          enqueueSnackbar(t("preset_categories_added_successfully"), {
            variant: "success",
            autoHideDuration: 3000,
            preventDuplicate: true,
          });
        } else {
          setTimeout(() => {
            getProfile(res);
          }, 4000);
        }
      }
    });
  };

  const getCategories = async (payload) => {
    // let endUrl = "";
    // if (!use_global_categories) {
    //   endUrl = `?dataset=${dataset}`;
    // } else {
    //   endUrl = `?is_global=true`;
    // }
    await APICall("get", EndPoints.category).then((response) => {
      if (response.status === 200 && response.data) {
        if (payload?.updatePosition) {
          let batchArray = [];
          const updatedFlattenData = tree.flattenTree(response.data.results);
          updatedFlattenData?.forEach((item) => {
            batchArray.push({
              uuid: item?.uuid,
              position: item.position,
            });
          });
          categoryRef.current?.batchUpdateCategory({
            payload: batchArray,
            doNotUpdate: true,
          });
          getCategories();
        } else {
          dispatch(setCategories({ data: response.data.results, type: "all" }));
          dispatch(setPopupStatus3(null));
          dispatch(setStageLoadingText(null));
        }
      }
    });
  };

  const checkIsCategoriesEmpty = async () => {
    // let endUrl = "";
    // if (!use_global_categories) {
    //   endUrl = `?dataset=${dataset}`;
    // } else {
    //   endUrl = `?is_global=true`;
    // }
    await APICall("get", EndPoints.category).then((response) => {
      if (response.status === 200 && response.data) {
        if (response.data.results?.length > 0) {
          setTimeout(async () => {
            await checkIsCategoriesEmpty();
          }, 4000);
        } else {
          enqueueSnackbar(t("categories_deleted_successfully"));
          dispatch(setCategories({ data: response.data.results, type: "all" }));
          dispatch(setPopupStatus3(null));
          dispatch(setStageLoadingText(null));
        }
      }
    });
  };

  const deleteBatch = async (uuids) => {
    dispatch(setStageLoadingText("common_process_loader_text"));
    await APICall("post", EndPoints.category + "batch_delete/", uuids).then(
      async (response) => {
        if (response.status === 204) {
          setTimeout(async () => {
            await checkIsCategoriesEmpty();
          }, 4000);
        }
      }
    );
  };

  //functions
  const onClosePopOver = () => {
    setAnchorEl(false);
    setCategoryPreset(null);
  };

  const onConfirmDelete = () => {
    const uuids = selectionCategories?.map((o1) => o1.uuid);
    deleteBatch(uuids);
  };

  const onConfirmApply = ({ payload }) => {
    applyCategoryPresets(payload?.item?.uuid);
  };

  const onClickOption = (e, item) => {
    setCategoryPreset(item);
    if (item?.uuid === "delete_all_category") {
      store.dispatch(
        setPopupStatus3({
          open: true,
          overlay_type: "delete",
          onConfirm: onConfirmDelete,
          payload: {
            title: t("Delete"),
            message: `${t("Are_you_sure_delete_all_category")}`,
          },
        })
      );
    } else if (item?.uuid === "manage_rules") {
      navigate("/settings/rules");
    } else {
      setAnchorEl(e?.currentTarget);
    }
  };

  const onClickGuide = (e, item) => {
    let title = item?.title
      ?.replace("business_models_", "")
      ?.replace("accounting_", "");
    finbanInfoRef.current?.open({
      url: `https://finban.io/documentation/categories/${title}`,
    });
  };

  const onClickApply = (e, item) => {
    store.dispatch(
      setPopupStatus3({
        open: true,
        overlay_type: "delete",
        onConfirm: onConfirmApply,
        showConfirmCheckBox: true,
        confirmCheckBoxLabel: "add_category_preset_confirm_check_text",
        payload: {
          title: t("Attention"),
          message: `${t("are_you_sure_want_add_category_preset")}`,
          item,
        },
      })
    );
  };

  useEffect(() => {
    getCategoryPresets();
  }, []);

  return (
    <List
      sx={{
        display: "flex",
        flexDirection: "column",
        width: "22rem",
        minHeight: "6rem",
        p: "0.5rem",
        borderRadius: theme.borderRadius.main,
      }}
    >
      <CustomPopover
        type="anchor"
        anchorEl={anchorEl}
        onClose={onClosePopOver}
        onClick={undefined}
        width={"fit-content"}
        border="none"
        justifyContent="flex-start"
        iconSize={"1.375rem"}
        icon
        iconStyle={{ p: 0 }}
        paperStyle={{
          border: 0,
          cursorCol: "pointer",
        }}
        iconView={<></>}
        hoverBG={"transparent"}
      >
        <CategoryPresetView
          categoryPresets={categoryPresets}
          categoryRef={categoryRef}
          theme={theme}
          onClickApply={(e) => onClickApply(e, categoryPresets)}
        />
      </CustomPopover>
      {isFetching ? (
        <ComponentLoader loading hideNoDataPlaceholder height="4rem" />
      ) : (
        <Stack>
          <OptionsViewSections
            options={options?.accounting_}
            categoryPresets={categoryPresets}
            onClickOption={onClickOption}
            onClickGuide={onClickGuide}
            onClickApply={onClickApply}
            t={t}
            theme={theme}
          />
          <OptionsViewSections
            options={options?.business_models_}
            categoryPresets={categoryPresets}
            onClickOption={onClickOption}
            onClickGuide={onClickGuide}
            onClickApply={onClickApply}
            t={t}
            theme={theme}
          />

          <OptionsViewSections
            options={options?.others}
            categoryPresets={categoryPresets}
            onClickOption={onClickOption}
            onClickGuide={onClickGuide}
            onClickApply={onClickApply}
            t={t}
            theme={theme}
          />
          <OptionsViewSections
            options={CategoryOptionViewCustomImportOptions}
            categoryPresets={categoryPresets}
            onClickOption={onClickOption}
            t={t}
            theme={theme}
          />
          <OptionsViewSections
            key={"manage_rules"}
            options={[
              {
                uuid: "manage_rules",
                title: "Manage Rules",
                icon: <PiMagicWandFill fontSize={"1.3rem"} />,
                isAction: true,
              },
            ]}
            onClickOption={onClickOption}
            t={t}
            theme={theme}
          />
          <OptionViewItem
            key={"delete_all"}
            item={{
              uuid: "delete_all_category",
              title: "category_option_delete_all",
              icon: <DeleteIcon />,
              isAction: true,
            }}
            theme={theme}
            t={t}
            onClick={onClickOption}
            primarySx={{ color: theme.palette.color.red[600] }}
            secondarySx={{ color: theme.palette.color.red[400] }}
            sx={{
              color: theme.palette.color.red[600],
              "& .MuiListItemIcon-root": {
                color: theme.palette.color.red[600],
              },

              "&:hover": {
                backgroundColor: theme.palette.color.red[100],
              },
            }}
          />
        </Stack>
      )}
    </List>
  );
};

const CategoryPresetView = ({ categoryPresets, theme, onClickApply }) => {
  const { t } = useTranslation();
  const allCategories = useRef([]);
  const [isFetching, setIsFetching] = useState(false);
  const [tab, setTab] = useState(1);
  const [data, setData] = useState({});

  //api
  const getPresetsCategories = async (uuid) => {
    setIsFetching(true);
    await APICall(
      "get",
      EndPoints.category_presets + `${uuid}/categories/`
    ).then(async (response) => {
      if (response.status === 200 && response.data) {
        const updatedFlattenData = tree.flattenTree(response.data.results);

        allCategories.current = updatedFlattenData;
        setData(allCategories.current?.filter((item) => item?.type === tab));
        setIsFetching(false);
      } else {
        setData([]);
        setIsFetching(false);
      }
    });
  };

  useEffect(() => {
    if (categoryPresets?.uuid) {
      getPresetsCategories(categoryPresets?.uuid);
    }
  }, [categoryPresets]);

  useUpdateEffect(() => {
    setData(allCategories.current?.filter((item) => item?.type === tab));
  }, [tab]);

  return (
    <Stack
      sx={{
        p: "1rem",
        borderRadius: theme.borderRadius.main,
        width: "50rem",
        minHeight: "20rem",
      }}
    >
      <Stack
        direction={"row"}
        justifyContent={"space-between"}
        alignItems={"center"}
        sx={{ width: "100%" }}
      >
        <Typography
          variant="body1"
          fontWeight={"fontWeightMediumBold"}
          color="color.mainTitle"
        >
          {categoryPresets?.title}
        </Typography>
        <TailwindButton
          onClick={onClickApply}
          text={t("replace_category_with_preset")}
          tooltip={t("replace_category_with_preset_tooltip")}
          backgroundColor={theme.palette.primary.main}
          sx={{
            fontSize: "0.7rem",
            lineHeight: "0.7rem",
            p: "0.5rem",
          }}
        />
      </Stack>
      <Typography
        variant="caption"
        fontWeight={"fontWeightMedium"}
        color="color.description"
        sx={{
          width: "60%",
        }}
      >
        {categoryPresets?.description}
      </Typography>
      {isFetching ? (
        <ComponentLoader loading hideNoDataPlaceholder height="4rem" />
      ) : data?.length > 0 ? (
        <Grid container spacing={4} sx={{ m: "auto" }}>
          <TabsView value={tab} setValue={setTab} />
          <Stack
            direction={"row"}
            alignItems={"center"}
            sx={{
              width: "100%",
              mt: "1rem",
            }}
          >
            <Grid item xs={1.3}></Grid>
            <Grid item xs={7.3}>
              <Typography variant={"caption"} fontWeight={"fontWeightBold"}>
                {t("Title")}
              </Typography>
            </Grid>
            <Grid item xs={3.8}>
              <Typography variant={"caption"} fontWeight={"fontWeightBold"}>
                {t("category_type")}
              </Typography>
            </Grid>
          </Stack>
          <Stack
            sx={{
              overflowY: "auto",
              width: "100%",
              m: "auto",
              maxHeight: "20rem",
              ...theme?.thinScrollBar,
            }}
          >
            {data?.map((item, index) => (
              <TreeItem
                isOverlay
                isPreview
                level={item?.depth - 1}
                item={item}
                key={item?.uuid}
                index={index}
              />
            ))}
          </Stack>
        </Grid>
      ) : null}
    </Stack>
  );
};

const CategoryTypeSwitch = () => {
  const dispatch = useDispatch();
  const payload = useRef(null);

  //redux
  const dataSetData = useSelector((state) => state.boardSlice?.dataSetData);
  const beta_enabled = useSelector(
    (state) => state.settingsSlice?.profile?.beta_enabled
  );
  //state
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);

  //Api
  const updateDataSetByID = async (id, obj) => {
    const dataSetList = store.getState().boardSlice?.dataSetList;

    await APICall("patch", EndPoints.datasets + `${id}/`, obj, {
      doNotCatchRespond: true,
    }).then((response) => {
      if (response.status === 200 && response.data) {
        let data = [...dataSetList];
        const index = data.findIndex((o1) => o1.uuid === id);
        if (index > -1) {
          data[index] = response.data;
        }
        data.sort((a, b) => (a.position > b.position ? 1 : -1));
        dispatch(
          setDataSetData({
            partial: {
              use_global_categories: !dataSetData?.use_global_categories,
            },
          })
        );
        dispatch(setDataSetList(data));
        setOpen(false);
        setLoading(false);
        queryClient.resetQueries({
          predicate: (query) => query.queryKey[0] === "transactions",
        });
      } else {
        setLoading(false);
      }

      if (response.status === 400 && response.data?.use_global_categories) {
        const unCategorizeMessage =
          "This dataset has categorized transactions. Please uncategorize them first.";

        const isUnCategorize =
          response?.data?.use_global_categories?.[0] === unCategorizeMessage;

        payload.current = {
          doUnCategorize: isUnCategorize,
          message: response?.data?.use_global_categories?.[0],
          addText: isUnCategorize ? "confirm_category_level_change" : "Ok",
        };
        setOpen(true);
      }
    });
  };

  const unCategorizedDataSetByID = async (id) => {
    await APICall(
      "post",
      EndPoints.datasets + `${id}/uncategorize_transactions/`,
      null,
      {
        doNotCatchRespond: true,
      }
    ).then((response) => {
      if (response.status === 200 && response.data) {
        updateDataSetByID(dataSetData?.uuid, {
          use_global_categories: !dataSetData?.use_global_categories,
        });
      } else {
        setLoading(false);
      }
    });
  };

  const onChangeType = (e, value) => {
    if (
      dataSetData?.uuid &&
      dataSetData?.use_global_categories?.toString() !== value?.toString()
    ) {
      updateDataSetByID(dataSetData?.uuid, {
        use_global_categories: !dataSetData?.use_global_categories,
      });
    }
  };

  const onChangeCategoryLevel = () => {
    if (payload.current?.doUnCategorize) {
      setLoading(true);
      unCategorizedDataSetByID(dataSetData?.uuid);
    } else {
      setOpen(false);
    }
  };

  if (Constant?.isProd || !beta_enabled) {
    return null;
  }
  return (
    <>
      <CategoryLevelAttentionOverlay
        open={open}
        setOpen={setOpen}
        loading={loading}
        onAdd={onChangeCategoryLevel}
        title="category_level_attention_title"
        message={
          payload.current?.message || "category_level_attention_description"
        }
        addText={payload.current?.addText || "confirm_category_level_change"}
        // message="category_level_attention_description"
        // addText={"confirm_category_level_change"}
      />
      <ThemeToggle
        value={dataSetData?.use_global_categories}
        onChange={onChangeType}
        options={[
          {
            value: false,
            label: "category_type_label_dataset",
            tooltip: "category_type_label_dataset_tooltip",
          },
          {
            value: true,
            label: "category_type_label_general",
            tooltip: "category_type_label_general_tooltip",
          },
        ]}
        sx={{ mr: "3rem" }}
      />
    </>
  );
};

const TabsView = ({ value, setValue }) => {
  const location = useLocation();

  //life cycle method
  useEffect(() => {
    if (location?.state?.fromCategories) {
      setValue(location?.state?.isOutflow ? 2 : 1);
    }
  }, [location]);

  return <ThemeTabs options={tabOptions} tab={value} setTab={setValue} />;
};
