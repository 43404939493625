import {
  useImperativeHandle,
  useCallback,
  forwardRef,
  useEffect,
  useState,
  useRef,
} from "react";
import { Box, List, ListItem, Skeleton } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { enqueueSnackbar } from "notistack";
import { useLocation } from "react-router";

import {
  getExternalDataSourceConnection,
  getFinApiDataSourceConnection,
  getFinApiQueryKey,
  sleep,
} from "../../../Helper/data";
import {
  setIsAccountSelectOverlayOpen,
  deleteDataSource,
  setDataSource,
  setAccounts,
} from "../../../store/slices/global";
import IntegrationsDialogView from "../../../components/Header/DatasetHeader/IntegrationsDialogView";
import {
  setStageLoadingText,
  setRefreshData,
} from "../../../store/slices/appmain";
import TransactionSystemDialogView from "./TransactionSystemDialogView";
import { setIsAllHeaderApiFetched } from "../../../store/slices/common";
import { setPopupStatus3 } from "../../../store/slices/datasets";
import ManualAccountDialogView from "./ManualAccountDialogView";
import CustomModal from "../../../components/Model/CustomModal";
import useSubscriptions from "../../../hooks/useSubscriptions";
import { setDataSetData } from "../../../store/slices/board";
import { setAddFrom } from "../../../store/slices/settings";
import EndPoints from "../../../APICall/EndPoints";
import initialData from "./../../../Helper/data";
import TinkDialogView from "./TinkDialogView";
import { queryClient } from "../../../App";
import APICall from "../../../APICall";
import theme from "../../../theme";

const DSConnections = forwardRef((props, _ref) => {
  const dispatch = useDispatch();
  const location = useLocation();
  const { t } = useTranslation();
  const last_sync_date = useRef(null);
  const dSObj = useRef(null);
  const DS_FOR_SYNC = useRef([]);
  const allowSyncConnectedOnly = useRef(false);
  const isDSChanged = useRef(false);
  const fetchType = useRef(null);
  const [isSubscriptionValid] = useSubscriptions();

  //redux
  const dataSetData = useSelector((state) => state.boardSlice?.dataSetData);
  const dataSource = useSelector((state) => state.globalSlice.dataSource);
  const addFrom = useSelector((state) => state.settingsSlice.addFrom);
  const accountByDS = useSelector((state) => state.settingsSlice.accountByDS);
  const company_name = useSelector(
    (state) => state.settingsSlice?.profile?.company_name
  );
  const transactionSystemByName = useSelector(
    (state) => state.globalSlice.transactionSystemByName
  );
  const transactionSystemById = useSelector(
    (state) => state.globalSlice.transactionSystemById
  );
  const datasetAccountList = useSelector(
    (state) => state.boardSlice.datasetAccountList
  );

  //state
  const [open, setOpen] = useState(false);
  const [isErrorLogsModalOpen, setIsErrorLogsModalOpen] = useState(false);
  const [isIntegrationOverlayOpen, setIntegrationOverlayOpen] = useState(false);

  const currency = dSObj.current?.ds?.currency
    ? dSObj.current?.ds?.currency
    : props?.clickable && dataSetData?.uuid
      ? dataSetData?.currency
      : "EUR";
  //API
  const getIntegrationConnection = async ({ DS }) => {
    const FinApiQueryKey = getFinApiQueryKey({
      data_source: DS?.uuid,
    });

    const IntegrationsQueryKey = [
      "integrations",
      {
        data_source: DS?.uuid,
        apiType: "data_source_external_connections",
      },
    ];
    let connections;
    if (DS?.type === 10) {
      return null;
    }
    if (allowSyncConnectedOnly.current) {
      dispatch(
        setStageLoadingText(
          `${DS?.title}: ${t("checking integration connections")}`
        )
      );
    }

    if (DS?.type === 12) {
      connections = await queryClient.fetchQuery({
        queryKey: FinApiQueryKey,
        queryFn: async ({ signal }) => {
          const result = await getFinApiDataSourceConnection(DS?.uuid);
          if (result) {
            return result;
          }
        },
        backgroundFetch: true,
      });
    }
    if (DS?.type === 19) {
      connections = await queryClient.fetchQuery({
        queryKey: IntegrationsQueryKey,
        queryFn: async ({ signal }) => {
          const result = await getExternalDataSourceConnection(DS?.uuid);
          if (result) {
            return result;
          }
        },
        backgroundFetch: true,
      });
    }
    return connections;
  };

  //-------------------------------
  const updateConsents = async ({ DS, _fetchType }) => {
    fetchType.current = _fetchType;
    dispatch(setStageLoadingText("Updating Consents .."));
    if (DS?.state !== 1) {
      await updateDataSourceByID(DS?.uuid, { state: 1 });
    }
    await postFinApiDSConsents(DS);
  };

  const postFinApiDSConsents = async (DS) => {
    await APICall(
      "post",
      EndPoints.integrations + `${DS?.uuid}/finapi/update-consents/`
    ).then(async (response) => {
      if (response.status === 200 && response.data) {
        if (response.data?.length !== 0) {
          const web_form = response.data?.[0];
          if (web_form?.status === "IN_PROGRESS") {
            await sleep(5000);
            await getFinApiDSConsents(DS);
          }
        } else {
          dispatch(setStageLoadingText(null));
        }
      } else {
        dispatch(setStageLoadingText(null));
        if (
          response?.data?.[0] ===
          "The data source has no completed bank connection to update consent."
        ) {
          enqueueSnackbar(
            t(
              `this_integration_has_no_completed_bank_connection_to_update_consent`
            ),
            {
              variant: "error",
              autoHideDuration: 5000,
            }
          );
        }
        if (response?.data?.[0]?.code) {
          enqueueSnackbar(t(response?.data?.[0]?.code), {
            variant: "error",
            autoHideDuration: 5000,
          });
        }
        if (response?.data?.code) {
          enqueueSnackbar(t(response?.data?.code), {
            variant: "error",
            autoHideDuration: 5000,
          });
        }
      }
    });
  };

  const getFinApiDSConsents = async (DS) => {
    await APICall(
      "get",
      EndPoints.integrations + `${DS?.uuid}/finapi/update-consents/`
    ).then(async (response) => {
      if (response.status === 200 && response.data) {
        if (response.data?.length !== 0) {
          const web_form = response.data?.[0]?.web_form;
          const ready_to_use_web_form = response.data?.[0];

          if (web_form?.status === "NOT_YET_OPENED") {
            dispatch(setStageLoadingText(null));
            dispatch(
              setAddFrom({
                targetUrl: location.pathname,
                integration_id: DS?.uuid,
                last_sync_date: DS?.last_sync_date,
                token: web_form?.id,
                type: "Consents",
                isFetchNew: fetchType.current === "fetch-new",
                isFetchAll: fetchType.current === "fetch-all",
              })
            );
            dispatch(
              setIsAccountSelectOverlayOpen({
                open: true,
                isFinApiWebForm: true,
                isFetchNew: fetchType.current === "fetch-new",
                isFetchAll: fetchType.current === "fetch-all",
                payload: {
                  token: web_form?.id,
                },
              })
            );
            return;
          }

          if (
            web_form?.status?.includes("ABORTED") ||
            web_form?.status?.includes("EXPIRED") ||
            web_form?.status?.includes("ERROR") ||
            ready_to_use_web_form?.status?.includes("ABORTED") ||
            ready_to_use_web_form?.status?.includes("EXPIRED") ||
            ready_to_use_web_form?.status?.includes("ERROR")
          ) {
            enqueueSnackbar(t("finapi_something_went_wrong"), {
              variant: "error",
              autoHideDuration: 5000,
              preventDuplicate: true,
            });
            dispatch(setStageLoadingText(null));
            return;
          } else if (ready_to_use_web_form?.status === "IN_PROGRESS") {
            await sleep(5000);
            await getFinApiDSConsents(DS);
          } else if (ready_to_use_web_form?.status === "WEB_FORM_REQUIRED") {
            await getDataSource();
          } else if (!web_form) {
            await afterUpdateFinApiConsents(DS?.uuid);
            return;
          }
        } else {
          await postFinApiDSConsents(DS);
        }
      } else {
        dispatch(setStageLoadingText(null));
      }
    });
  };

  const afterUpdateFinApiConsents = async (DSID) => {
    dispatch(setStageLoadingText("Updating Consents .."));
    await APICall("get", EndPoints.integrations + `${DSID}/`).then(
      async (response) => {
        if (response.status === 200 && response.data) {
          if (addFrom?.isFetchAll || !response.data?.last_sync_date) {
            dispatch(setIsAccountSelectOverlayOpen(null));
            fetchAllTransaction(response.data);
          } else if (addFrom?.isFetchNew || true) {
            dispatch(setIsAccountSelectOverlayOpen(null));
            fetchNewTransaction(response.data);
          } else {
            // await getDataSource();
            // await getAccountsApi();
            // const account = accountByDS?.[DSID]?.[0];
            // const options = {
            //   predicate: (query) =>
            //     query.queryKey[0] === "integrations" &&
            //     query.queryKey[1]?.bank === account?.bank &&
            //     query.queryKey[1]?.apiType === "data_source_finapi_connections",
            // };
            // setTimeout(() => {
            //   queryClient.resetQueries(options);
            //   dispatch(setIsAccountSelectOverlayOpen(null));
            //   dispatch(setAddFrom(null));
            //   dispatch(setIsAllHeaderApiFetched(true));
            //   dispatch(setStageLoadingText(null));
            // }, 0);
          }

          enqueueSnackbar(t("Consents Updated Successfully"), {
            variant: "success",
            autoHideDuration: 5000,
          });
        }
      }
    );
  };

  //-------------------------------
  const fetchNewTransaction = async (DS) => {
    const connections = await getIntegrationConnection({ DS });
    //---------------- for sync all ds----------------//
    if (
      allowSyncConnectedOnly.current &&
      connections !== "COMPLETED" &&
      DS_FOR_SYNC.current?.length > 0
    ) {
      const [DS] = DS_FOR_SYNC.current?.splice(0, 1);
      fetchNewTransaction(DS);
      return;
    }

    //---------------- if required update consents  ----------------//
    if (
      DS?.type === 12 &&
      connections === "USER_ACTION_REQUIRED" &&
      !allowSyncConnectedOnly.current
    ) {
      await updateConsents({ DS, _fetchType: "fetch-new" });
      return;
    }

    //---------------- if consents not required or completed----------------//
    last_sync_date.current = DS?.last_sync_date;
    if (
      (DS?.type === 12 &&
        (connections === "COMPLETED" || connections === "UPDATED")) ||
      (DS?.type === 19 && connections === "UPDATED")
    ) {
      fetchType.current = "fetch-new";
      if (allowSyncConnectedOnly.current) {
        dispatch(
          setStageLoadingText(`${DS?.title}: ${t("Fetching new transactions")}`)
        );
      } else {
        dispatch(setStageLoadingText(t("Fetching new transactions")));
      }
      const type = DS?.type === 12 ? "finapi" : "external";
      await APICall(
        "post",
        EndPoints.integrations + `${DS?.uuid}/${type}/fetch-new/`,
        {}
      ).then(async (response) => {
        if (response.status === 200 && response.data.success) {
          if (DS?.type === 12) {
            await sleep(1000);
            checkFinApiConnection(DS);
          } else {
            await sleep(5000);
            getDataSourceById(DS);
          }
        } else {
          dispatch(setStageLoadingText(null));
        }
      });
    } else {
      enqueueSnackbar(
        t(
          "please allow consent for this data source to fetch new transactions"
        ),
        {
          variant: "warning",
          autoHideDuration: 5000,
        }
      );
    }
  };

  const fetchAllTransaction = async (DS) => {
    const connections = await getIntegrationConnection({ DS });

    //---------------- if required update consents  ----------------//
    if (DS?.type === 12 && connections === "USER_ACTION_REQUIRED") {
      await updateConsents({ DS, _fetchType: "fetch-all" });
      return;
    }
    last_sync_date.current = DS?.last_sync_date;

    if (
      (DS?.type === 12 &&
        (connections === "COMPLETED" || connections === "UPDATED")) ||
      (DS?.type === 19 && connections === "UPDATED")
    ) {
      fetchType.current = "fetch-all";
      // console.log("Refetching all transactions ==>",DS?.title);
      dispatch(setStageLoadingText("Refetching all transactions"));

      const type = DS?.type === 12 ? "finapi" : "external";
      await APICall(
        "post",
        EndPoints.integrations + `${DS?.uuid}/${type}/fetch-all/`,
        {}
      ).then(async (response) => {
        if (response.status === 200 && response.data.success) {
          if (DS?.type === 12) {
            await sleep(1000);
            checkFinApiConnection(DS);
          } else {
            await sleep(5000);
            getDataSourceById(DS);
          }
        }
      });
    }
  };

  const syncAllIntegrations = async () => {
    const filteredDS = datasetAccountList?.filter(
      (DS) => DS?.type === 12 || DS?.type === 19
    );
    if (filteredDS?.length > 0) {
      allowSyncConnectedOnly.current = true;
      DS_FOR_SYNC.current = filteredDS;
      const [DS] = DS_FOR_SYNC.current?.splice(0, 1);
      await fetchNewTransaction(DS);
    } else {
      enqueueSnackbar(t("no_data_source_to_sync"), {
        preventDuplicate: true,
        variant: "info",
        autoHideDuration: 5000,
      });
    }
  };

  const checkFinApiConnection = async (DS) => {
    const connections = await getIntegrationConnection({ DS });
    if (connections === "COMPLETED") {
      await getDataSourceById(DS);
    } else if (
      connections === "USER_ACTION_REQUIRED" &&
      !allowSyncConnectedOnly.current
    ) {
      await postFinApiDSConsents(DS);
    } else {
      if (!allowSyncConnectedOnly.current) {
        await getFinApiDSConsents(DS);
      } else {
        if (fetchType.current === "fetch-new") {
          const [DS] = DS_FOR_SYNC.current?.splice(0, 1);
          await fetchNewTransaction(DS);
        }
      }
    }
  };

  const getDataSourceById = async (DS) => {
    await APICall("get", EndPoints.integrations + `${DS?.uuid}/`).then(
      async (response) => {
        if (response.status === 200 && response.data) {
          if (
            last_sync_date.current?.toString() ===
            response?.data?.last_sync_date?.toString()
          ) {
            if (DS?.type === 12) {
              await sleep(5000);
              await checkFinApiConnection(DS);
            } else {
              await sleep(5000);
              await getDataSourceById(DS);
            }
          } else {
            if (DS_FOR_SYNC.current?.length === 0) {
              // console.log(" last_sync_date updated  ==>", DS?.title);
              await getAccountsApi();
              await getDataSource();
              DS_FOR_SYNC.current = [];
              allowSyncConnectedOnly.current = false;
              const options = {
                predicate: (query) =>
                  initialData?.ResetAllDataOptions?.filter(
                    (o1) => !["rules", "integrations", "invoices"]?.includes(o1)
                  )?.includes(query?.queryKey?.[0]),
                type: "active",
              };
              const account = accountByDS?.[DS?.uuid]?.[0];
              const bank_options = {
                predicate: (query) =>
                  query.queryKey[0] === "integrations" &&
                  query.queryKey[1]?.bank === account?.bank &&
                  query.queryKey[1]?.apiType ===
                    "data_source_finapi_connections",
              };
              setTimeout(() => {
                queryClient.resetQueries(bank_options, { cancelRefetch: true });
                queryClient.resetQueries(options, { cancelRefetch: true });
                dispatch(setRefreshData(Date.now()));
                dispatch(setStageLoadingText(null));
                dispatch(setIsAllHeaderApiFetched(true));
                dispatch(setIsAccountSelectOverlayOpen(null));
              }, 1000);
            } else {
              if (fetchType.current === "fetch-new") {
                const [DS] = DS_FOR_SYNC.current?.splice(0, 1);
                fetchNewTransaction(DS);
              }
            }
          }
        }
      }
    );
  };

  const updateDataSetByID = async (id, obj) => {
    await APICall("patch", EndPoints.datasets + `${id}/`, obj).then(
      (response) => {
        if (response.status === 200 && response.data) {
          if (dataSetData?.uuid === id) {
            dispatch(setDataSetData(response.data));
          }
        }
      }
    );
  };

  const archiveDS = async (DS) => {
    const FinApiQueryKey = getFinApiQueryKey({ data_source: DS?.uuid });

    const IntegrationsQueryKey = [
      "integrations",
      {
        data_source: DS?.uuid,
        apiType: "data_source_external_connections",
      },
    ];
    if (DS?.type === 10) {
      return null;
    }
    if (DS?.type === 12) {
      await queryClient.fetchQuery({
        queryKey: FinApiQueryKey,
        queryFn: async ({ signal }) => {
          const result = await getFinApiDataSourceConnection(DS?.uuid);
          if (result) {
            return result;
          }
        },
        backgroundFetch: true,
      });
    }
    if (DS?.type === 19) {
      await queryClient.fetchQuery({
        queryKey: IntegrationsQueryKey,
        queryFn: async ({ signal }) => {
          const result = await getExternalDataSourceConnection(DS?.uuid);
          if (result) {
            return result;
          }
        },
        backgroundFetch: true,
      });
    }

    const changedState = DS?.state !== 1 ? 1 : 2;

    if (changedState !== 1 && DS?.datasets?.length > 0) {
      DS?.datasets?.forEach(async (connected_data_source_obj) => {
        await removeDataSourceToDataSet(
          connected_data_source_obj?.dataset,
          connected_data_source_obj?.uuid,
          DS
        );
      });
    }
    await updateDataSourceByID(DS?.uuid, { state: changedState });
    await getDataSource();
  };

  const updateDataSourceByID = async (id, obj) => {
    await APICall("patch", EndPoints.integrations + `${id}/`, obj).then(
      (response) => {
        if (response.status === 200 && response.data) {
        }
      }
    );
  };

  const removeDataSourceToDataSet = async (dataset_id, integration_id) => {
    await APICall(
      "delete",
      EndPoints.datasets + `${dataset_id}/data_sources/${integration_id}/`
    ).then((response) => {
      if (response.status === 204) {
      }
    });
  };

  const getDataSource = useCallback(async () => {
    await APICall("get", EndPoints.integrations).then((response) => {
      if (response.status === 200 && response.data) {
        let data = response.data.results;
        dispatch(setDataSource(data));
        setTimeout(() => {
          let options = {
            predicate: (query) =>
              initialData.ResetAllDataOptions.includes(query.queryKey[0]),
            type: "active",
          };
          queryClient.invalidateQueries(options, { cancelRefetch: true });
          dispatch(setRefreshData(Date.now()));
        }, 500);
      }
    });
  }, []);

  const getAccountsApi = async () => {
    await APICall("get", EndPoints.accounts).then((response) => {
      if (response.status === 200 && response.data) {
        let data = response.data.results;
        dispatch(setAccounts(data));
      }
    });
  };

  const checkDemoDsConnections = async () => {
    let DEMO = dataSource?.find(
      (o1) => o1.title?.toLowerCase() === "demo" && o1.datasets?.length > 0
    );
    if (DEMO) {
      let title = "";
      if (company_name) {
        title = company_name;
      } else {
        const userLanguage = navigator.language || navigator.userLanguage;
        let splittedLang = userLanguage?.split("-");
        let lang = splittedLang[0];

        if (lang === "de") {
          title = "Dein Unternehmen";
        } else {
          title = "Your company";
        }
      }
      let obj = DEMO?.datasets?.[0];
      await updateDataSetByID(obj?.dataset, { title });
      await removeDataSourceToDataSet(obj?.dataset, obj?.uuid);
      await getDataSource();
    }
  };

  //
  const createNewDS = async (card) => {
    checkDemoDsConnections();

    const obj = {
      title: dSObj.current?.ds?.title,
      note: dSObj.current?.ds?.note || dSObj.current?.card?.name,
      alias: dSObj.current?.ds?.alias || "",
      type: dSObj.current?.ds?.type,
      state: 1,
      datasets: [],
      transaction_system: dSObj.current?.transaction_systems?.uuid,
      currency,
    };
    await APICall("post", EndPoints.integrations, obj).then(
      async (response) => {
        if (response.status === 201 && response.data) {
          dSObj.current = {
            ...dSObj.current,
            ds: response.data,
          };
          if (props?.clickable && dataSetData?.uuid) {
            const obj = {
              active: true,
              dataset: dataSetData?.uuid,
              data_source: response.data?.uuid,
            };
            await addDataSourceDataset(dataSetData?.uuid, obj);
          }
          await getDataSource();
          if (props?.setTab) props?.setTab(10);

          if (card?.name === "Lexoffice") {
            await getApiKey(dSObj.current?.ds?.uuid);
          } else if (card?.name === "FinApi_Account") {
            if (props?.closeAvailableOverlay) props?.closeAvailableOverlay();
            setOpen(false);
            setDs_in_process(null);
            dispatch(
              setIsAccountSelectOverlayOpen({
                open: true,
                isBankSelection: true,
                payload: {
                  integration_id: response.data?.uuid,
                },
              })
            );
          }
        } else {
          setDs_in_process(null);
        }
      }
    );
  };

  const getApiKey = async (integration_id) => {
    await APICall(
      "get",
      EndPoints.integrations + `${integration_id}/external/config/`
    )
      .then((response) => {
        if (response.status === 200 && response.data) {
          dSObj.current = { ...dSObj.current, ...response.data };
          if (response.data?.authorization_url) {
            dispatch(setPopupStatus3(null));
            // dispatch(setIsAllHeaderApiFetched(false));
            dispatch(
              setAddFrom({
                integration_id: integration_id,
                targetUrl: window.location.pathname,
              })
            );

            setTimeout(() => {
              let win = window.open(response.data?.authorization_url, "_self");
              win.focus();
            }, 100);
          }
        }
      })
      .finally(() => {
        setDs_in_process(null);
      });
  };

  const addDataSourceDataset = async (dataset_id, obj) => {
    await APICall(
      "post",
      EndPoints.datasets + `${dataset_id}/data_sources/`,
      obj
    ).then((response) => {
      if (response.status === 201 && response.data) {
      }
      if (response.status === 400 && response.data) {
        if (response?.data?.data_source?.length > 0) {
          enqueueSnackbar(t("different_ds_not_allowed"), {
            variant: "warning",
            autoHideDuration: 5000,
          });
        }
      }
    });
  };

  const createLexOfficeDS = async ({ transaction_systems, card }) => {
    dispatch(setPopupStatus3({ key: "loading", value: true }));

    const count =
      dataSource?.filter(
        (o1) => o1?.transaction_systems === transaction_systems?.uuid
      )?.length ?? 0;

    dSObj.current = {
      ...dSObj.current,
      card: card,
      modalType: "Add",
      transaction_systems,
      ds: {
        ...dSObj.current?.ds,
        type: card?.type,
        title: `lexoffice ${count > 0 ? `(${count})` : ""}`,
        currency,
      },
    };

    createNewDS(dSObj.current?.card);
  };

  const deleteDataSourceIdApi = async (id, transaction_systems, card) => {
    await APICall("delete", EndPoints.integrations + `${id}/`).then(
      (response) => {
        if (response.status === 204 && response) {
          dispatch(deleteDataSource(id));
          createLexOfficeDS({
            transaction_systems: transaction_systems,
            card: card,
          });
        }
      }
    );
  };

  //functions
  const handleClickCard = (card) => {
    if (
      isSubscriptionValid({
        showMessage: true,
        ds_type: card?.type,
        type: "data_source",
      })
    ) {
      setDs_in_process(card.name);

      if (
        [
          "ManualDS",
          "Moco",
          "SevDesk",
          "BuchhaltungsButler",
          "easybill",
          "GetMyInvoices",
          "Google Sheets API",
          "Google Sheets API 2",
          "Lexoffice",
          "Excel import",
        ].includes(card?.name)
      ) {
        dSObj.current = {
          ...dSObj.current,
          card: card,
          ds: {
            type: card?.type,
          },
          transaction_systems: transactionSystemByName?.[card?.name]?.[0],
          modalType: "Add",
        };

        setOpen(card?.name);
      }
      // if (["Lexoffice"].includes(card?.name)) {
      //   let transaction_systems = transactionSystemByName?.[card?.name]?.[0];

      //   if (transaction_systems?.data_source_limit) {
      //     let lex_office_ds = dataSource.filter(
      //       (o1) => o1.transaction_system === transaction_systems?.uuid
      //     );

      //     if (lex_office_ds.length >= transaction_systems?.data_source_limit) {
      //       dispatch(
      //         setPopupStatus3({
      //           open: true,
      //           overlay_type: "delete",
      //           type: "warn",
      //           showIcon: true,
      //           onConfirm: () =>
      //             onConfirmDelete({
      //               transaction_systems,
      //               card,
      //               lex_office_ds: lex_office_ds?.[0]?.uuid,
      //             }),
      //           onClose: () => {
      //             setDs_in_process(null);
      //           },
      //           payload: {
      //             title: <Translate i18nkey={"warn_lexoffice_title"} />,
      //             message: <Translate i18nkey={"warn_lexoffice_message"} />,
      //             confirmText: (
      //               <Translate i18nkey={"warn_lexoffice_confirm_text"} />
      //             ),
      //           },
      //         })
      //       );

      //       return;
      //     }
      //   }
      //   dispatch(
      //     setPopupStatus3({
      //       id: "simple-popper",
      //       overlay_type: "warn_overlay",
      //       open: true,
      //       onConfirm: () => {
      //         createLexOfficeDS({
      //           transaction_systems: transaction_systems,
      //           card: card,
      //         });
      //       },
      //       onClose: () => {
      //         setDs_in_process(null);
      //       },
      //       payload: {
      //         title: <Translate i18nkey={"info_lexoffice_title"} />,
      //         message: <Translate i18nkey={"info_lexoffice_message"} />,
      //         confirmText: (
      //           <Translate i18nkey={"info_lexoffice_confirm_text"} />
      //         ),
      //       },
      //     })
      //   );
      // }

      if (["FinApi_Account"].includes(card?.name)) {
        let count =
          dataSource?.filter((o1) => o1.type === card?.type)?.length ?? 0;
        dSObj.current = {
          ...dSObj.current,
          card: { name: card?.name },
          modalType: "Add",
          ds: {
            ...dSObj.current?.ds,
            type: card?.type,
            title: `${t("FinApi_Account")} ${count > 0 ? `(${count})` : ""}`,
            currency,
          },
        };
        createNewDS(dSObj.current?.card);
      }
    }
  };

  const onClickEditIntegration = (ds, acc, card) => {
    let show = "";
    ds = { ...ds, isConnected: acc ? true : false };
    if (ds.type === 1) {
      dSObj.current = {
        ...dSObj.current,
        ds: ds,
        acc: acc,
        card: card,
        modalType: "Edit",
        manualAccountItem: acc,
      };
      show = "ManualDS";
    }

    if (ds.type === 12) {
      dSObj.current = {
        ...dSObj.current,
        ds: ds,
        acc: acc,
        card: card,
        modalType: "Edit",
      };
      show = "FinApi_Account";
    }
    if (ds.type === 19) {
      let transaction_system =
        transactionSystemById?.[ds?.transaction_system]?.[0];
      dSObj.current = {
        ...dSObj.current,
        ds: ds,
        acc: acc,
        card: card,
        modalType: "Edit",
        transaction_system: transaction_system,
      };
      show = transaction_system?.name;
    }
    setOpen(show);
  };

  const handleClickClose = (doUpdate = false, _clickable = false) => {
    if (doUpdate) {
      setTimeout(() => {
        getDataSource();
        getAccountsApi();
      }, 500);
      if (props?.setTab) props?.setTab(10);
    }
    setOpen(null);
    setDs_in_process(null);
    if (_clickable) {
      props.isDSChanged.current = true;
      if (props?.closeAvailableOverlay) props?.closeAvailableOverlay();
    }
  };

  const onConfirmDelete = ({ transaction_systems, card, lex_office_ds }) => {
    setDs_in_process(null);
    if (lex_office_ds) {
      deleteDataSourceIdApi(lex_office_ds, transaction_systems, card);
    }
  };

  const setDs_in_process = (value) => {
    if (props?.setDs_in_process) {
      props?.setDs_in_process(value);
    }
  };

  const onClickErrorLogs = useCallback((ds, error) => {
    setIsErrorLogsModalOpen({ ds, error });
  }, []);

  const handleClickCloseIntegrationOverlay = useCallback(() => {
    setIntegrationOverlayOpen(null);
    if (isDSChanged?.current) {
      getDataSource();
    }
  }, [getDataSource]);

  const openIntegrationOverlay = useCallback((type) => {
    setIntegrationOverlayOpen(type);
  }, []);

  useImperativeHandle(_ref, () => ({
    checkDemoDsConnections,
    fetchNewTransaction,
    fetchAllTransaction,
    updateConsents,
    afterUpdateFinApiConsents,
    archiveDS,
    handleClickCard,
    onClickEditIntegration,
    syncAllIntegrations,
    onClickErrorLogs,
    openIntegrationOverlay,
  }));

  return (
    <>
      {Boolean(isIntegrationOverlayOpen) ? (
        <IntegrationsDialogView
          open={Boolean(isIntegrationOverlayOpen)}
          type={isIntegrationOverlayOpen}
          handleClickClose={handleClickCloseIntegrationOverlay}
          isDSChanged={isDSChanged}
        />
      ) : null}
      {!!isErrorLogsModalOpen ? (
        <ErrorLogsModal
          open={isErrorLogsModalOpen}
          setOpen={setIsErrorLogsModalOpen}
        />
      ) : null}
      {open === "ManualDS" ? (
        <ManualAccountDialogView
          open={true}
          handleClickClose={handleClickClose}
          cell={dSObj.current}
          clickable={props?.clickable}
        />
      ) : null}
      {open === "FinApi_Account" ? (
        <TinkDialogView
          open={true}
          handleClickClose={handleClickClose}
          cell={dSObj.current}
        />
      ) : null}
      {[
        "Lexoffice",
        "Moco",
        "SevDesk",
        "BuchhaltungsButler",
        "easybill",
        "GetMyInvoices",
        "Google Sheets API",
        "Google Sheets API 2",
        "Excel import",
      ].includes(open) ? (
        <TransactionSystemDialogView
          open={true}
          handleClickClose={handleClickClose}
          cell={dSObj.current}
          clickable={props?.clickable}
        />
      ) : null}
    </>
  );
});

export default DSConnections;

const ErrorLogsModal = ({ open, setOpen }) => {
  const { t } = useTranslation();
  const [errorData, setErrorData] = useState([]);
  const [loading, setLoading] = useState(false);

  const getErrorLogsFromLogId = async (ds, errorId) => {
    setLoading(true);
    await APICall(
      "get",
      EndPoints.integrations + `${ds?.uuid}/logs/${errorId}/errors/`
    ).then((response) => {
      setLoading(false);
      if (response.status === 200 && response.data) {
        setErrorData(response.data);
      }
    });
  };

  const handleClose = () => {
    setOpen(false);
  };

  const formatErrorMessage = (error_message) => {
    const match = error_message.match(/\[<class '(\w+)\.(\w+)'>\]/);
    if (match) {
      const className = match[1];
      const errorType = match[2];
      return `<b>${className}.${errorType}</b>`;
    }
    return error_message;
  };

  useEffect(() => {
    if (open?.error) {
      getErrorLogsFromLogId(open?.ds, open?.error?.uuid);
    }
  }, [open]);

  return (
    <CustomModal
      heading={t("integration_error_modal_heading")}
      open={open}
      onClose={handleClose}
      hideAction
    >
      {" "}
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          width: "50rem",
          backgroundColor: theme.palette.color.white,
          px: "1.5rem",
          py: "1rem",
        }}
      >
        {loading ? (
          Array.from({ length: 4 }).map((item, index) => (
            <ListItem key={index} disablePadding>
              <Skeleton
                key={index}
                animation="wave"
                variant="rounded"
                width={"100%"}
                height={"2rem"}
                sx={{ my: "0.5rem" }}
              />
            </ListItem>
          ))
        ) : (
          <List>
            {errorData?.map((item) => {
              const message = `${formatErrorMessage(item.error_message)} at line ${item?.identifier}`;
              return (
                <ListItem disablePadding key={item?.uuid}>
                  <b style={{ marginRight: "0.5rem" }}>{"->"}</b>
                  <span
                    dangerouslySetInnerHTML={{
                      __html: message,
                    }}
                  />
                </ListItem>
              );
            })}
          </List>
        )}
      </Box>
    </CustomModal>
  );
};
