import {
  TableContainer,
  Typography,
  Container,
  TableBody,
  TableCell,
  TableHead,
  Checkbox,
  Skeleton,
  TableRow,
  useTheme,
  Divider,
  Popover,
  Alert,
  alpha,
  Paper,
  Table,
  Card,
  Box,
} from "@mui/material";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import { useDispatch, useSelector } from "react-redux";
import { useQuery } from "@tanstack/react-query";
import { useTranslation } from "react-i18next";
import Button from "@mui/lab/LoadingButton";
import React, { useEffect } from "react";
import { useSnackbar } from "notistack";
import { Helmet } from "react-helmet";
import parse from "html-react-parser";
import { useState } from "react";

import {
  getTailwindColor,
  updateLanguage,
  isPlanExpired,
  formatAmount,
} from "../../../Helper/data";
import DeleteConfirmationModal from "../../../components/Model/DeleteConfirmationModal";
import TailwindButton from "../../../components/Overlay/TailwindButton";
import { setProfile } from "../../../store/slices/settings";
import { Color, Constant, Fonts } from "../../../Helper";
import ThemeTabs from "../../../components/ThemeTabs";
import EndPoints from "../../../APICall/EndPoints";
import APICall from "../../../APICall";

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

const tabOptions = [
  {
    value: 1,
    label: "Tab_subscription",
  },
  {
    value: 2,
    label: "Tab_invoices",
  },
];

const BillingReasons = {
  automatic_pending_invoice_item_invoice:
    "Automatic pending invoice item invoice",
  manual: "Manual",
  subscription: "Subscription",
  subscription_create: "Subscription create",
  subscription_cycle: "Subscription cycle",
  subscription_threshold: "Subscription threshold",
  subscription_update: "Subscription update",
  upcoming: "Upcoming",
};

const headerCellStyle = {
  color: "white",
  fontWeight: "bold",
  fontSize: "1rem",
  fontFamily: Fonts.Text,
};
const CellStyle = {
  color: Color.tailwind.grey[900],
  fontWeight: 400,
  fontSize: "0.9rem",
  fontFamily: Fonts.Text,
};

const Subscription = () => {
  const theme = useTheme();
  const { t } = useTranslation();
  //state
  const [tab, setTab] = useState(1);

  return (
    <Container
      maxWidth={"none"}
      sx={{
        width: "100%",
        height: "100%",
        display: "inline-flex",
        overflow: "auto",
        flexDirection: "column",
        ml: "90px",
        px: "0px !important",
        pb: "3rem",
        ...theme.thinScrollBar,
      }}
    >
      <Typography
        variant="h5"
        color="color.slate.700"
        fontWeight={"fontWeightMediumBold"}
        sx={{
          mt: "4rem",
        }}
      >
        {t("Subscription")}
      </Typography>
      <Typography
        variant="subtitle2"
        color="color.description"
        fontWeight={"fontWeightMedium"}
        sx={{
          my: "0.5rem",
          mb: "1.5rem",
        }}
      >
        {t("subscription_description")}
      </Typography>
      <Divider
        sx={{
          mb: "1rem",
        }}
      />

      <ThemeTabs
        options={tabOptions}
        tab={tab}
        setTab={setTab}
        sx={{ width: "80%" }}
      />
      {tab === 1 ? <SubscriptionView /> : <Billing />}
    </Container>
  );
};

export default Subscription;

const SubscriptionView = () => {
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation();
  //redux
  const subscription = useSelector(
    (state) => state.settingsSlice?.subscription
  );
  const is_owner = useSelector(
    (state) => state.settingsSlice?.profile?.is_owner
  );
  const [anchorEl, setAnchorEl] = useState(null);
  const [tableUrl, setTableUrl] = useState();
  const [scriptUrl, setscriptUrl] = useState();
  const [loader1, setLoader1] = useState(false);
  const [loader2, setLoader2] = useState(false);
  const [isConfirmOpen, setIsConfirmOpen] = useState(false);
  const [upgrade_plan_options, set_upgrade_plan_options] = useState([]);

  const openDS = Boolean(anchorEl);

  //lifeCycleMethod
  useEffect(() => {
    getProfile();
    getPricingTableApi();
  }, []);

  //api
  const getProfile = async () => {
    await APICall("get", EndPoints.profile).then((response) => {
      if (response.status === 200 && response.data) {
        let data = response.data;
        updateLanguage(response.data?.locale);
        dispatch(setProfile(data));
        setLoader2(false);
        setLoader1(false);
      }
    });
  };

  const getPricingTableApi = async () => {
    await APICall("get", EndPoints.pricing_table).then((response) => {
      if (response.status === 200 && response.data) {
        let embeded_code = response.data?.embeded_code.replaceAll(`\\`, "");
        let array = embeded_code.split(`</script> `);
        setscriptUrl(array[0] + "</script>");
        setTableUrl(array[1]);
      }
    });
  };

  const cancelSubscription = async () => {
    setLoader2(true);
    await APICall("delete", EndPoints.cancelSubscription).then((response) => {
      if (response.status === 204 && response) {
        enqueueSnackbar(t("Subscription Canceled Successfully"), {
          variant: "success",
          autoHideDuration: 4000,
        });
        getProfile();
        onCloseConfirm();
      } else {
        setLoader2(false);
        onCloseConfirm();
      }
    });
  };

  const reactivateSubscription = async () => {
    setLoader2(true);
    await APICall("patch", EndPoints.reactivateSubscription, {}).then(
      (response) => {
        if (response.status === 200 && response) {
          enqueueSnackbar(t("Subscription Reactivated Successfully"), {
            variant: "success",
            autoHideDuration: 4000,
          });
          getProfile();
        } else {
          setLoader2(false);
        }
      }
    );
  };

  const upgradeSubscription = async (payload) => {
    setLoader1(true);
    await APICall("post", EndPoints.upgradeSubscription, payload).then(
      (response) => {
        if (response.status === 201 && response) {
          handlePopoverClose();
          enqueueSnackbar(t("Subscription Upgraded Successfully"), {
            variant: "success",
            autoHideDuration: 4000,
          });
          setTimeout(() => {
            getProfile();
          }, 2000);
        } else {
          setLoader1(false);
        }
      }
    );
  };

  const getPlansList = async () => {
    await APICall("get", EndPoints.plansList).then((response) => {
      if (response.status === 200 && response.data) {
        set_upgrade_plan_options(
          response.data.results?.filter((item) =>
            ["Starter", "Business", "Professional"].includes(
              item?.product?.name
            )
          )
        );
      }
    });
  };

  //function
  const onClickCancel = () => {
    if (
      subscription?.stripe_subscription?.cancel_at_period_end &&
      subscription?.stripe_subscription?.canceled_at
    ) {
      reactivateSubscription();
    } else {
      setIsConfirmOpen(true);
    }
  };

  const onClickUpgrade = (event) => {
    getPlansList();
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const onChangeSelectedAccount = (option) => {
    if (subscription?.planId !== option.id || isPlanExpired(subscription)) {
      let obj;
      let updatedData = [
        ...upgrade_plan_options.map((item) => {
          if (item?.id === option?.id) {
            obj = { ...item, selected: !item?.selected };
            return obj;
          }
          return (obj = { ...item, selected: false });
        }),
      ];
      set_upgrade_plan_options(updatedData);
    }
  };

  const onClickSave = () => {
    let selectedPlan = upgrade_plan_options?.find((o1) => o1.selected);
    if (selectedPlan) {
      let obj = {
        plan: selectedPlan?.id,
        proration: "always_invoice", //"always_invoice|create_prorations|none"
      };
      upgradeSubscription(obj);
    } else {
      enqueueSnackbar(t("Please select a plan"), {
        variant: "warning",
        autoHideDuration: 3000,
      });
    }
  };

  const onCloseConfirm = () => {
    setIsConfirmOpen(false);
  };

  const onOk = () => {
    cancelSubscription();
  };

  const popUp = () => {
    return (
      <Popover
        open={openDS}
        anchorEl={anchorEl}
        onClose={handlePopoverClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        slotProps={{
          paper: {
            sx: {
              borderRadius: 1.5,
              boxShadow: Constant.OverlayBoxShadow,
            },
          },
        }}
        sx={{
          mt: "0.5rem",
        }}
      >
        <Typography
          variant="div"
          sx={{
            display: "inline-flex",
            alignItems: "center",
            fontSize: "1rem",
            fontFamily: Fonts.Text,
            fontWeight: 600,
            textAlign: "left",
            width: "100%",
            height: "4rem",
            px: "1rem",
            backgroundColor: Color.HeaderBG,
          }}
        >
          {t("All Plan List")}
        </Typography>
        <Divider />
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            height: "fit-content",
            minHeight: "3.125rem",
            px: "1rem",
            width: "fit-content",
            minWidth: "22.5rem",
            position: "relative",
            backgroundColor: Color.BodyBG,
          }}
        >
          {upgrade_plan_options?.map((item) => {
            return (
              <Box
                key={item?.id}
                onClick={() => onChangeSelectedAccount(item)}
                style={{
                  cursor: "pointer",
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <Checkbox
                  icon={icon}
                  checkedIcon={checkedIcon}
                  style={{
                    marginRight: "0.5rem",
                    color: subscription?.planId === item.id && "transparent",
                  }}
                  checked={item?.selected ?? false}
                  disabled={
                    !isPlanExpired(subscription) &&
                    subscription?.planId === item.id
                  }
                />
                <Typography
                  sx={{
                    color:
                      subscription?.planId === item.id && Color.themeColor2,
                    fontWeight: subscription?.planId === item.id && 600,
                    fontFamily: Fonts.Text,
                  }}
                >
                  {`${item?.amount} ${item?.currency}/${item?.interval} for ${item?.product?.name}`}
                </Typography>
              </Box>
            );
          })}
        </Box>
        <Box
          sx={{
            display: "inline-flex",
            flexDirection: "row-reverse",
            alignItems: "center",
            backgroundColor: Color.FooterBG,
            alignSelf: "end",
            width: "100%",
            mt: "1.5rem",
            p: "1rem",
          }}
        >
          <TailwindButton
            text={t("Save")}
            onClick={onClickSave}
            loading={loader1}
          />
          <TailwindButton
            type="cancel"
            text={t("Cancel")}
            onClick={handlePopoverClose}
          />
        </Box>
      </Popover>
    );
  };
  return (
    <>
      {anchorEl && popUp()}
      <DeleteConfirmationModal
        open={isConfirmOpen}
        onClose={onCloseConfirm}
        onOk={onOk}
        title={`${subscription?.name} plan`}
        cancel="Cancel"
        confirm="Continue"
        message={"Are you sure you want to cancel this plan?"}
        loading={loader2}
      />
      {!is_owner ? (
        <Alert severity="info" sx={{ mt: "8rem", width: "fit-content" }}>
          {t("user_permission_area_restricted_info")}
        </Alert>
      ) : null}
      <Helmet>{is_owner && scriptUrl && parse(scriptUrl)}</Helmet>
      {is_owner &&
        ((subscription?.name && subscription?.name === "Trial") ||
          (subscription?.stripe_subscription?.cancel_at_period_end &&
            subscription?.stripe_subscription?.canceled_at) ||
          subscription?.stripe_subscription?.status === "canceled") && (
          <Box
            sx={{
              width: "80%",
            }}
          >
            {tableUrl && parse(tableUrl)}
          </Box>
        )}
      {is_owner &&
        subscription?.name &&
        subscription?.name !== "Trial" &&
        subscription?.stripe_subscription?.status !== "canceled" && (
          <Card
            sx={{
              width: "25rem",
              height: "25rem",
              borderRadius: 2,
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              position: "absolute",
              top: "40%",
              left: "40%",
              transform: "translate(-18%, -39%)",
            }}
          >
            <Typography
              component={"h3"}
              sx={{
                position: "absolute",
                top: "15%",
                fontWeight: 500,
                fontFamily: Fonts.Text,
                fontSize: "1.2rem",
              }}
            >
              {t("Your Subscription")}:
            </Typography>
            <Typography
              component={"h2"}
              sx={{
                position: "absolute",
                top: "24%",
                fontWeight: 600,
                fontFamily: Fonts.Text,
                color: alpha(Color.themeColor2, 0.9),
              }}
            >
              {subscription?.name}
            </Typography>
            <Box
              sx={{
                display: "inline-flex",
                justifyContent: "space-between",
                width: "86%",
                position: "absolute",
                bottom: "20%",
              }}
            >
              <Button
                onClick={onClickUpgrade}
                variant="contained"
                loading={loader1}
                loadingIndicator="Loading…"
                sx={{
                  width: "fit-content",
                  fontFamily: Fonts.Text,
                  textTransform: "capitalize",
                  minWidth: "10rem",
                }}
              >
                {t("Change Plan")}
              </Button>
              <Button
                onClick={onClickCancel}
                variant="contained"
                loading={loader2}
                loadingIndicator="Loading…"
                sx={{
                  minWidth: "10rem",
                  width: "fit-content",
                  fontFamily: Fonts.Text,
                  textTransform: "capitalize",
                }}
              >
                {subscription?.stripe_subscription?.cancel_at_period_end &&
                subscription?.stripe_subscription?.canceled_at
                  ? t("Reactivate Plan")
                  : t("Cancel Plan")}
              </Button>
            </Box>
          </Card>
        )}
    </>
  );
};

const Billing = () => {
  const { t } = useTranslation();
  const theme = useTheme();
  const profile = useSelector((state) => state.settingsSlice?.profile);
  const isAllHeaderApiFetched = useSelector(
    (state) => state.commonSlice.isAllHeaderApiFetched
  );
  const is_owner = useSelector(
    (state) => state.settingsSlice?.profile?.is_owner
  );
  const [filter, setFilter] = useState({
    billing_reason: "",
    paid: "unknown",
    attempted: "unknown",
  });

  const handleInvoiceClick = (invoice) => {
    window.open(invoice?.hosted_invoice_url, "blank");
  };

  const getInvoices = async (params) => {
    let data = [];
    let url =
      EndPoints?.invoices +
      `?billing_reason=${filter?.billing_reason}&paid=${filter?.paid}&attempted=${filter?.attempted}`;
    await APICall("get", url, null, params?.config).then((response) => {
      if (response.status === 200 && response.data) {
        data = response.data;
      }
    });
    return data;
  };

  const Invoices = useQuery({
    queryKey: [
      "invoices",
      {
        email: profile?.email,
        apiType: "invoices",
        billing_reason: filter?.billing_reason,
        paid: filter?.paid,
        attempted: filter?.attempted,
      },
    ],
    queryFn: ({ signal }) => {
      let params = {
        config: {
          signal: signal,
          doNotCatchRespond: true,
        },
        billing_reason: filter?.billing_reason,
        paid: filter?.paid,
        attempted: filter?.attempted,
      };

      const result = getInvoices(params);
      if (result) {
        return result;
      }
    },
    // cacheTime: 30000,
    backgroundFetch: true,
    enabled: isAllHeaderApiFetched && !!profile?.email && !!filter?.paid,
    priority: 1,
    false: !is_owner,
  });

  const invoices = Invoices?.data?.results;

  const getStatusColor = (status) => {
    switch (status) {
      case "paid":
        return "green"; // Green color for 'paid' status
      case "pending":
        return "orange"; // Orange color for 'pending' status
      case "overdue":
        return "red"; // Red color for 'overdue' status
      default:
        return "inherit"; // Default color
    }
  };

  if (!is_owner) {
    return (
      <Box sx={{ width: "100%", display: "flex", justifyContent: "center" }}>
        <Alert severity="info" sx={{ mt: "8rem", width: "fit-content" }}>
          {t("user_permission_area_restricted_info")}
        </Alert>
      </Box>
    );
  }

  return (
    <Box
      sx={{
        display: "inline-flex",
        width: "100%",
        alignItems: "start",
        flexDirection: "column",
        backgroundColor: Color.appThemeBg,
        position: "relative",
        borderRadius: 3,
        // maxHeight: "60vh",
        height: "calc(100% - 15rem)",
      }}
    >
      {Invoices?.isFetching ? (
        <Skeleton
          animation="wave"
          variant="rounded"
          width={"80%"}
          height={"100%"}
        />
      ) : invoices?.length > 0 ? (
        <TableContainer
          component={Paper}
          elevation={1}
          sx={{
            display: "flex",
            justifyContent: "start",
            flexDirection: "column",
            width: "80%",
            height: "100%",
            borderRadius: "12px",
            overflow: "auto",
            ...theme.thinScrollBar,
          }}
        >
          <Table component="span" sx={{ minWidth: "60rem" }}>
            <TableHead
              component="span"
              sx={{ backgroundColor: Color.tailwind.purple[600] }}
            >
              <TableRow component="span">
                <TableCell component="span" sx={headerCellStyle}>
                  Invoice Number
                </TableCell>
                <TableCell component="span" sx={headerCellStyle}>
                  Account Name
                </TableCell>
                <TableCell component="span" sx={headerCellStyle}>
                  Amount Due
                </TableCell>
                <TableCell component="span" sx={headerCellStyle}>
                  Amount Paid
                </TableCell>
                <TableCell component="span" sx={headerCellStyle}>
                  Billing Reason
                </TableCell>
                <TableCell component="span" sx={headerCellStyle}>
                  Status
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody
              component="span"
              sx={{
                borderRadius: "12px",
                overflowY: "auto",
              }}
            >
              {invoices?.map((invoice, index) => (
                <TableRow
                  component={Paper}
                  elevation={1}
                  key={invoice.id}
                  onClick={() => handleInvoiceClick(invoice)}
                  sx={{
                    backgroundColor: index % 2 === 0 ? "#f5f5f5" : "white",
                    cursor: "pointer",
                    "&:hover": {
                      backgroundColor: Color.tailwind.purple[50],
                    },
                  }}
                >
                  <TableCell component="span" sx={CellStyle}>
                    {invoice.number}
                  </TableCell>
                  <TableCell component="span" sx={CellStyle}>
                    {invoice.account_name}
                  </TableCell>
                  <TableCell component="span" sx={CellStyle}>
                    {formatAmount({
                      amount: invoice.amount_due,
                      dataset: { currency: invoice?.currency },
                    })}
                  </TableCell>
                  <TableCell component="span" sx={CellStyle}>
                    {formatAmount({
                      amount: invoice.amount_paid,
                      dataset: { currency: invoice?.currency },
                    })}
                  </TableCell>
                  <TableCell component="span" sx={CellStyle}>
                    {BillingReasons[invoice.billing_reason]}
                  </TableCell>

                  <TableCell
                    component="span"
                    sx={{
                      color: getStatusColor(invoice.status),
                      textTransform: "uppercase",
                    }}
                  >
                    <span
                      style={{
                        backgroundColor: getTailwindColor(
                          getStatusColor(invoice.status),
                          100
                        ),
                        color: getTailwindColor(
                          getStatusColor(invoice.status),
                          600
                        ),
                        padding: "4px 8px",
                        borderRadius: "4px",
                        display: "inline-block",
                        fontFamily: Fonts.Text,
                        fontWeight: 600,
                        fontSize: "0.8rem",
                      }}
                    >
                      {invoice.status}
                    </span>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      ) : (
        <div
          style={{
            width: "80%",
            height: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Typography
            sx={{
              textAlign: "center",
              fontSize: "1.2rem",
              fontWeight: 500,
              fontFamily: Fonts.Text,
              color: Color.black,
              alignSelf: "center",
              alignItems: "center",
              display: "inline-flex",
            }}
          >
            {t("No_Data_Found")}
          </Typography>
        </div>
      )}
    </Box>
  );
};
