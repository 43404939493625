import {
  createRoutesFromChildren,
  useNavigationType,
  BrowserRouter,
  useLocation,
  matchRoutes,
} from "react-router-dom";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import React, { Suspense, useLayoutEffect, useEffect } from "react";
import { PersistGate } from "redux-persist/integration/react";
import { CircularProgress, Grow } from "@mui/material";
import { ThemeProvider } from "@mui/material/styles";
import { SnackbarProvider } from "notistack";
import * as Sentry from "@sentry/react";
import { Provider } from "react-redux";
import "./styles.css";
import "./fonts.css";

import GlobalContextWrapper from "./GlobalContextWrapper";
import SpinLoader from "./components/Spinner";
import store, { persistor } from "./store";
import packageJson from "../package.json";
import ErrorPage from "./Pages/ErrorPage";
import Tour from "./components/Tour";
import { Constant } from "./Helper";
import AppRouter from "./routes";
import theme from "./theme";

Sentry.init({
  release: "finban@" + packageJson?.version,
  dsn: process.env.REACT_APP_SENTRY_DNS,
  environment: process.env.REACT_APP_MODE,
  integrations: [
    Sentry.reactRouterV6BrowserTracingIntegration({
      useEffect,
      useLocation,
      useNavigationType,
      createRoutesFromChildren,
      matchRoutes,
    }),
    Sentry.replayIntegration(),
  ],
  ignoreErrors: [
    "Error: timeout exceeded",
    "The object can not be found here.",
    "React ErrorBoundary NotFoundError: The object can not be found here.",
    "The object can not be found here.",
    "cannot ping/update sw.js TypeError: Failed to fetch",
    "TypeError: Failed to fetch",
    "TypeError: NetworkError when attempting to fetch resource.",
    "Request aborted",
    "Network Error",
    "CancelledError",
    "Request failed with status code 401",
    "UnknownError: Internal error opening backing store for indexedDB.open.",
    "ReferenceError: globalThis is not defined",
    "Object captured as exception with keys: message",
    "Object captured as promise rejection with keys: message",
    "ResizeObserver loop completed with undelivered notifications.",
    "UnknownError: Connection to Indexed Database server lost. Refresh the page to try again",
    "Rendered fewer hooks than expected. This may be caused by an accidental early return statement",
    "Failed to execute 'insertBefore' on 'Node': The node before which the new node is to be inserted is not a child of this node",
    "Failed to execute 'removeChild' on 'Node': The node to be removed is not a child of this node",
    "NotFoundError: Node.insertBefore: Child to insert before is not a child of this node",
    "NotFoundError: Node.removeChild: The node to be removed is not a child of this node",
    `Blocked a frame with origin "https://app.finban.io" from accessing a cross-origin frame. Protocols, domains, and ports must match.`,
    `Blocked a frame with origin "https://staging.finban.io" from accessing a cross-origin frame. Protocols, domains, and ports must match.`,
    `Blocked a frame with origin "https://test.finban.io" from accessing a cross-origin frame. Protocols, domains, and ports must match.`,
  ],
  beforeSend(event) {
    // Ignore Service Worker registration errors from any domain
    if (event.exception && event.exception.values) {
      const errorMessage = event.exception.values[0].value;

      // Check if the error is related to Service Worker registration failure
      if (errorMessage.includes("Failed to register a ServiceWorker")) {
        if (!window?.location?.origin?.includes(".finban.io/")) {
          return null; // Ignore errors from other domains
        }
      }
    }

    // Continue sending other events
    return event;
  },
  normalizeDepth: 7,
  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  tracesSampleRate: 0.7,
  // autoSessionTracking: false,
  // Set `tracePropagationTargets` to control for which URLs distributed tracing should be enabled
  tracePropagationTargets: [
    // "localhost",
    /^https:\/\/test\.finban\.io\/api/,
    /^https:\/\/staging\.finban\.io\/api/,
    /^https:\/\/app\.finban\.io\/api/,
  ],

  // Capture Replay for 10% of all sessions,
  // plus for 100% of sessions with an error

  replaysSessionSampleRate: 0.1,
  // replaysOnErrorSampleRate: 1.0,
  enabled: process.env.REACT_APP_MODE !== "development",
});

export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: 1,
      retryDelay: (attemptIndex) => Math.min(1000 * 2 ** attemptIndex, 30000),
      cacheTime: (1000 * 60 * 60 * 1) / 48, // 0.5 hour
      refetchOnWindowFocus: false, // default: true
      refetchOnMount: false, // default: true
      // notifyOnChangeProps: [
      //   "isFetching",
      //   "isLoading",
      //   "isSuccess",
      //   "data",
      //   "error",
      // ],
      // staleTime: 60000,
      // refetchOnReconnect: false, // default: true
      // logger: {
      //   log: (...args) => {
      //     // Log debugging information
      //   },
      //   warn: (...args) => {
      //     // Log warning
      //   },
      //   error: (...args) => {
      //     // Log error
      //   },
      // },
    },
  },
});

export default function App() {
  useLayoutEffect(() => {
    if (process.env.REACT_APP_PLAUSIBLE_DATA_DOMAIN) {
      const script = document.createElement("script");
      script.src = process.env.PLAUSIBLE_URL;
      script.defer = true;
      script.type = "text/javascript";
      script.setAttribute(
        "data-domain",
        process.env.REACT_APP_PLAUSIBLE_DATA_DOMAIN
      );
      document.body.append(script);
    }
  }, []);

  const onError = (error, errorInfo) => {
    if (Constant?.isTest) {
      console.log("Error  ==> ", error);
    }
    if (!Constant?.isDev) {
      const user = localStorage.getItem("LoggedInUser");

      Sentry.setExtra("onError  ==> ", {
        message: error?.message,
        response: error?.response,
        status: error?.status || error?.response?.status,
        user,
      });
      Sentry.captureException(error);
    }
    if (error?.message?.includes("Maximum call stack size exceeded")) {
      clearAllState();
      if (!window?.location?.pathname?.includes("/login")) {
        window.location.href = "/login";
      }
    }
  };

  const clearAllState = () => {
    localStorage.removeItem("token");
    localStorage.removeItem("refreshToken");
    localStorage.removeItem("last_visited_dataset");
    localStorage.removeItem("LoggedInUser");
    localStorage.removeItem("persist:root");
    queryClient.clear();
    // removeAllCookies();
    // removeCache();
  };

  return (
    <span>
      <ClarityAnalytics />
      <Sentry.ErrorBoundary
        FallbackComponent={ErrorPage}
        onError={onError}
        FallbackRender={null}
      >
        <Suspense
          fallback={
            <SpinLoader loading={true}>
              <div
                style={{
                  backgroundColor: theme.palette.primary.main,
                  borderRadius: theme.borderRadius.main,
                  color: theme.palette.color.white,
                  width: "2.4rem",
                  height: "2.4rem",
                  boxShadow: theme.boxShadow,
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <CircularProgress size="1.6rem" color="inherit" />
              </div>
            </SpinLoader>
          }
        >
          <SnackbarProvider
            maxSnack={3}
            variant="success"
            autoHideDuration={3000}
            anchorOrigin={{ vertical: "top", horizontal: "center" }}
            TransitionComponent={Grow}
          >
            <ThemeProvider theme={theme}>
              <Provider store={store}>
                <PersistGate persistor={persistor} loading={null}>
                  <QueryClientProvider client={queryClient}>
                    <BrowserRouter>
                      <GlobalContextWrapper>
                        <Tour />
                        <AppRouter />
                      </GlobalContextWrapper>
                    </BrowserRouter>
                  </QueryClientProvider>
                </PersistGate>
              </Provider>
            </ThemeProvider>
          </SnackbarProvider>
        </Suspense>
      </Sentry.ErrorBoundary>
    </span>
  );
}

const ClarityAnalytics = () => {
  function setCookie(name, value, days) {
    const expires = new Date(
      Date.now() + days * 24 * 60 * 60 * 1000
    ).toUTCString();
    document.cookie = `${name}=${value}; expires=${expires}; path=/`;
  }

  function getCookie(name) {
    const value = `; ${document.cookie}`;
    const parts = value.split(`; ${name}=`);
    if (parts.length === 2) return parts.pop().split(";").shift();
  }

  function deleteCookie(name) {
    document.cookie = `${name}=; expires=Thu, 01 Jan 1970 00:00:00 GMT; path=/`;
  }

  // Create or retrieve the session ID from cookies or sessionStorage
  function getOrCreateSessionId() {
    // First try to get the session ID from cookies
    let sessionId = getCookie("clarity_session_id");
    if (sessionId) {
      return sessionId;
    }

    // If no session ID in cookies, check sessionStorage
    let sessionData = sessionStorage.getItem("clarity_session_data");
    if (sessionData) {
      sessionData = JSON.parse(sessionData);
      sessionId = sessionData.sessionId;
      setCookie("clarity_session_id", sessionId, 7); // Set cookie for 7 days
      return sessionId;
    }

    // Create a new session if none exists
    sessionId = "session-" + Math.random().toString(36).substring(2, 11);
    const sessionStartTime = Date.now();
    sessionData = JSON.stringify({ sessionId, sessionStartTime });
    sessionStorage.setItem("clarity_session_data", sessionData);
    setCookie("clarity_session_id", sessionId, 7); // Set cookie for 7 days
    return sessionId;
  }

  // Check if the session has expired
  function hasSessionExpired(sessionStartTime) {
    const sessionTimeout = 30 * 60 * 1000; // 30 minutes
    return Date.now() - sessionStartTime > sessionTimeout;
  }

  function isBot(userAgent) {
    const botPatterns = [/bot/i, /crawler/i, /spider/i, /robot/i, /crawling/i];
    return botPatterns.some((pattern) => pattern.test(userAgent));
  }

  // Manage session by checking expiration and updating if needed
  function manageSession() {
    const userAgent = navigator.userAgent;

    if (isBot(userAgent)) {
      // Skip session creation for known bots or rate-limited users
      return null;
    }
    let sessionData = sessionStorage.getItem("clarity_session_data");
    if (sessionData) {
      sessionData = JSON.parse(sessionData);
      if (hasSessionExpired(sessionData.sessionStartTime)) {
        // Session has expired, remove old session data
        sessionStorage.removeItem("clarity_session_data");
        deleteCookie("clarity_session_id");
      }
    }

    // Always create or retrieve a valid session ID
    return getOrCreateSessionId();
  }

  let sessionId = null;
  let REACT_APP_CLARITY_ID = null;
  try {
    sessionId = process.env.REACT_APP_CLARITY_ID ? manageSession() : null;

    if (process.env.REACT_APP_CLARITY_ID) {
      REACT_APP_CLARITY_ID = (function (c, l, a, r, i, t, y) {
        c[a] =
          c[a] ||
          function () {
            (c[a].q = c[a].q || []).push(arguments);
          };
        t = l.createElement(r);
        t.async = 1;
        t.type = "text/javascript";
        t.src = "https://www.clarity.ms/tag/" + i;
        y = l.getElementsByTagName(r)[0];
        y.parentNode.insertBefore(t, y);
        c[a]("set", "session_id", sessionId);
      })(
        window,
        document,
        "clarity",
        "script",
        process.env.REACT_APP_CLARITY_ID
      );
    }
  } catch (error) {
    // Handle the error, but don't report it to Sentry
  }

  // const REACT_APP_SIB_CONVERSATIONS_WIDGET_ID = process.env
  //   .REACT_APP_SIB_CONVERSATIONS_WIDGET_ID
  //   ? (function (d, w, c) {
  //       w.SibConversationsID = process.env.REACT_APP_SIB_CONVERSATIONS_WIDGET_ID;
  //       w[c] =
  //         w[c] ||
  //         function () {
  //           (w[c].q = w[c].q || []).push(arguments);
  //         };
  //       var s = d.createElement("script");
  //       s.async = true;
  //       s.src =
  //         "https://conversations-widget.sendinblue.com/sib-conversations.js";
  //       if (d.head) d.head.appendChild(s);
  //     })(document, window, "SibConversations")
  //   : null;

  return (
    <>
      <script>
        {REACT_APP_CLARITY_ID}

        {/* {REACT_APP_SIB_CONVERSATIONS_WIDGET_ID} */}
      </script>
    </>
  );
};
