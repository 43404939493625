import { Alert, Card, CircularProgress } from "@mui/material";
import { useTranslation } from "react-i18next";
import Backdrop from "@mui/material/Backdrop";
import { useSelector } from "react-redux";
import Modal from "@mui/material/Modal";
import Fade from "@mui/material/Fade";
import Box from "@mui/material/Box";
import * as React from "react";

import { Images } from "../Helper";
import theme from "../theme";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  outline: "none",
};

export default function StageLoader() {
  const { t } = useTranslation();
  const stageText = useSelector((state) => state.appSlice.stageText);

  let open = Boolean(stageText);
  return (
    <Modal
      open={open}
      closeAfterTransition
      slotProps={{
        backdrop: {
          open: open,
          component: Backdrop,
          timeout: 500,
          style: {
            backdropFilter: "blur(6px)",
            WebkitBackdropFilter: "blur(6px)",
          },
        },
      }}
      sx={{
        zIndex: 9999,
      }}
    >
      <Fade in={open}>
        <Box sx={style}>
          <Card
            sx={{
              width: "50rem",
              height: "20rem",
              borderRadius: 2,
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              position: "relative",
            }}
          >
            <img
              src={Images.progress}
              height={124}
              alt="App_Logo"
              style={{ position: "absolute", top: 0 }}
            />
            <Box
              style={{
                position: "absolute",
                bottom: "5rem",
                left: "4rem",
                right: "4rem",
              }}
            >
              <Alert
                severity="info"
                icon={
                  <CircularProgress
                    color="inherit"
                    size={23}
                    sx={{
                      color: theme.palette.primary.main,
                    }}
                  />
                }
                sx={{
                  color: theme.palette.primary.main,
                  backgroundColor: theme.palette.primary[50],
                  display: "flex",
                  alignItems: "center",
                }}
              >
                {t(stageText)}
              </Alert>
            </Box>
          </Card>
        </Box>
      </Fade>
    </Modal>
  );
}
