import { Box, useMediaQuery } from "@mui/material";
import { useSelector } from "react-redux";

import Placeholder from "../../../../components/Placeholder";
import { Constant, Images } from "../../../../Helper";
import ComposedChartView from "./ComposedChartView";
import CommonView from "./CommonView";

const Chart = ({ column_width, start_column_extra_width }) => {
  const s2300 = useMediaQuery("(min-width:2300px)");
  // const s2220 = useMediaQuery("(min-width:2220px)");
  // const s2420 = useMediaQuery("(min-width:2420px)");
  // const s2550 = useMediaQuery("(min-width:2550px)");
  let marginLeft = Constant.chart_margin_left;
  let _extraSpace = Constant.chart_extra_offset;
  const height = "400px";
  if (s2300) {
    marginLeft = Constant.chart_margin_left_2300;
  }
  // if (s2220) {
  //   marginLeft = 119;
  // }
  // if (s2420) {
  //   marginLeft = 125;
  // }
  // if (s2550) {
  //   marginLeft = 149;
  // }
  const spacing = column_width + start_column_extra_width - marginLeft;

  //redux
  const precisionValue = useSelector(
    (state) => state.globalSlice.appliedFilterlist?.kanban?.precisionValue
  );
  const isChartExpanded = useSelector(
    (state) => state.chartSlice?.isChartExpanded
  );
  const kanban_daily_chart = useSelector(
    (state) => state.settingsSlice?.subscription?.kanban_daily_chart
  );
  const subscription = {
    kanban_daily_chart: kanban_daily_chart,
  };

  return (
    <Box
      style={{
        height: isChartExpanded ? height : 0,
        maxHeight: isChartExpanded ? height : 0,
        minHeight: isChartExpanded ? height : 0,
        transition: "all 500ms",
        marginLeft: marginLeft + _extraSpace,
        width:
          column_width *
            (Constant.column_count + (precisionValue === 1 ? 2 : 1)) +
          spacing,
      }}
    >
      <CommonView />
      {!subscription?.kanban_daily_chart && precisionValue === 2 ? (
        <Placeholder
          image={Images.kanban_daily_chart}
          description={"Only from Advanced Plan Available"}
          showText={isChartExpanded}
        />
      ) : isChartExpanded ? (
        <ComposedChartView column_width={column_width} />
      ) : (
        <span style={{ minHeight: "28.125rem" }}></span>
      )}
    </Box>
  );
};
export default Chart;
